import shallow from "zustand/shallow"
import {useQuery} from "react-query"
import {addSeconds} from "date-fns"
import servicePlayer from "services/Player"
// import useFreeNtryPowerBallStore from "screens/FreeNtryPowerball/store/useFreeNtryPowerBallStore"
import {useUserStore} from "stores/userStore"
import service from "services/Account/Login"

const useAppProvider = () => {
  const [token, setAuthToken, setExpiresIn, setUsernameUnmasked] = useUserStore(
    store => [
      store.authToken,
      store.setAuthToken,
      store.setExpiresIn,
      store.setUsernameUnmasked,
    ],
    shallow,
  )

  useQuery(
    ["PLAYER_SESSION"],
    async () => {
      const resp = await service.checkSession()
      return resp
    },
    {
      retry: false,
      refetchOnWindowFocus: true,
      enabled: !!token,
      onSuccess(data) {
        const {token, expires_in, username} = (data as any)?.data

        const dateNow = new Date()
        const timeExpiry = addSeconds(dateNow, Number(expires_in)).getTime()
        setAuthToken(token)
        setExpiresIn(timeExpiry)
        setUsernameUnmasked(username)
      },
    },
  )

  useQuery(
    ["PLAYER_CAN_PLAY_FREE_GAMES"],
    async () => {
      const resp = await servicePlayer.canPlayFreeGames()
      return resp?.data?.data
    },
    {
      retry: false,
      refetchOnWindowFocus: true,
      enabled: !!token,
      onSuccess(data) {
        const isFreeNtryEnabled = data?.ntry?.is_active ?? false
        // const freeNtry = useFreeNtryPowerBallStore.getState()
        // freeNtry.setCanPlay(isFreeNtryEnabled)
      },
    },
  )
}
export default useAppProvider
