import {useCallback, useEffect} from "react"
import navStore from "screens/NavHome/store/navStore"
import {PRIVATE_PATH_MAPPING, MY_PAGE, RECHARGE} from "screens/NavHome/utils/config"
import useMyProfileStore from "screens/MyProfile/store/useMyProfileStore"
import {useMyPageStore} from "screens/MyPage/store"
import {useUserStore} from "stores/userStore"
import shallow from "zustand/shallow"
import isEmpty from "lodash/isEmpty"
import { CHARGE_EXCHANGE, CRYPTO_WALLET } from "../../MyProfile/constants"
import { MOBILE_TAB_MAPPING, TabPage } from "../../MyPage/config"

const useMyPageNavBarProvider = () => {
  const [selectedSlideTitle] = navStore(state => [state.selectedSlideTitle], shallow)
  const { tab } = useMyProfileStore(
    store => ({
      tab: store.selectedTab,
    }),
    shallow,
  )

  const { user, mobileTab } = useMyPageStore(
    store => ({
      user: store.user,
      mobileTab: store.activeTab,
    }),
    shallow,
  )

  const initSubPage = useCallback((to: string) => {
    const nav = useMyProfileStore.getState()
    nav.setSelectedTab(to)

    const mobileNav = useMyPageStore.getState()
    mobileNav.setActiveTab(MOBILE_TAB_MAPPING[to])
  }, [])
  
  useEffect(() => {
    const authUser = useUserStore.getState().authToken
    const nav = navStore.getState()
    const isMyPage = nav.selectedSlideTitle === MY_PAGE

    if (!isMyPage || !authUser) return
    
    const urlPath = nav.urlPath
    const page = PRIVATE_PATH_MAPPING[urlPath]
    const items = page?.split("/")
    const hasSubPage = items?.length > 1

    if(hasSubPage) {
      const isRecharge = items[1] === "CHARGE_EXCHANGE"
      const tab = items[1]?.replace("CRYPTO_WALLET", CRYPTO_WALLET)
      if(!isRecharge)
        initSubPage(tab)
    }
    
  }, [selectedSlideTitle])

  useEffect(() => {
    const nav = navStore.getState()
    const isMyPage = nav.selectedSlideTitle === MY_PAGE
    const isRecharge = tab === CHARGE_EXCHANGE

    if (!isMyPage || isEmpty(tab) || isRecharge) return
    
    const key = `${MY_PAGE}/${tab.replace(CRYPTO_WALLET, "CRYPTO_WALLET")}`

    nav.updateUrlPath(key)
  }, [tab, selectedSlideTitle])


  useEffect(() => {
    const nav = navStore.getState()
    const isMyPage = nav.selectedSlideTitle === MY_PAGE
    const isRecharge = nav.selectedSlideTitle === RECHARGE
    const isRegister = mobileTab === TabPage.registerBank
    const isChange = mobileTab === TabPage.accountChange
    const isBank = isRegister && isChange

    if (!isMyPage || isEmpty(mobileTab) || isRecharge || isBank) return
    
    const keys = Object.keys(MOBILE_TAB_MAPPING)
    const pageKey =  keys.find(key => MOBILE_TAB_MAPPING[key] === mobileTab)
    const tab = pageKey ? `/${pageKey.replace(CRYPTO_WALLET, "CRYPTO_WALLET")}` : ""
    const key = `${MY_PAGE}${tab}`
    nav.updateUrlPath(key)
  }, [mobileTab, selectedSlideTitle])


  useEffect(() => {
    const nav = navStore.getState()
    const isMyPage = nav.selectedSlideTitle === MY_PAGE
    const isRegister = mobileTab === TabPage.registerBank
    const isChange = mobileTab === TabPage.accountChange
    const isNotBank = !isRegister && !isChange

    if (!isMyPage || !user || isNotBank ) return
    
    const isNew = user?.status === 1 || user?.status === 2
    const tab = isNew ? TabPage.registerBank : TabPage.accountChange
    const keys = Object.keys(MOBILE_TAB_MAPPING)
    const pageKey =  keys.find(key => MOBILE_TAB_MAPPING[key] === tab)
    const key = `${MY_PAGE}/${pageKey}`
    nav.updateUrlPath(key)
  }, [mobileTab, user?.status])


}

export default useMyPageNavBarProvider
