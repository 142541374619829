import NavStore from "screens/NavHome/types/NavStore"
import create from "zustand"
import {PROMOTIONS} from "screens/NavHome/utils/config"

const navStore = create<NavStore>((set, get) => ({
  selectedSlideTitle: PROMOTIONS,
  selectedIndex: 0,
  sliderIndex: 0,
  prevIndex: 0,
  renderedIndexes: [0, 1, 2],
  isDragging: false,
  bodyRef: null,
  sliderRef: null,
  maxScroll: 0,
  activeButtonRef: null,
  urlPath: "/events",
  setSelectedTitle: selectedSlideTitle => set({selectedSlideTitle}),
  setSelectedIndex: selectedIndex => {
    const prevIndex = get().selectedIndex
    set({
      prevIndex,
      selectedIndex,
    })
  },
  setSliderIndex: sliderIndex => set({sliderIndex}),
  setPrevIndex: prevIndex => set({prevIndex}),
  setRenderedIndexes: renderedIndexes => set({renderedIndexes}),
  setIsDragging: isDragging => set({isDragging}),
  setBodyRef: bodyRef => set({bodyRef}),
  setSliderRef: sliderRef => set({sliderRef}),
  setMaxScroll: maxScroll => set({maxScroll}),
  setActiveButtonRef: activeButtonRef => set({activeButtonRef}),
  setUrlPath: urlPath => set({urlPath}),

  validateUrlPath: () => {
    console.log("not loaded validateUrlPath")
  },
  setValidateUrlPath: validateUrlPath => set({validateUrlPath}),

  navToPublic: () => {
    console.log("not loaded navToPublic")
  },
  setNavToPublic: navToPublic => set({navToPublic}),

  updateUrlPath: () => {
    console.log("not loaded updateUrlPath")
  },
  setUpdateUrlPath: updateUrlPath => set({updateUrlPath}),

  referralCode: "ezbet",
  setReferralCode: referralCode => set({referralCode}),

  redirectTo: null,
  setRedirectTo: redirectTo => set({redirectTo})
}))

// export const navStore = create<NavStore>(
//     persist(
//       (set, get) => ({
//         selectedSlideTitle: HOME,
//         selectedIndex: 0,
//         prevIndex: 0,
//         renderedIndexes: [0, 1, 2],
//         isDragging: false,
//         bodyRef: null,
//         sliderRef: null,
//         setSelectedTitle: selectedSlideTitle => set({selectedSlideTitle}),
//         setSelectedIndex: selectedIndex => {
//             const prevIndex = get().selectedIndex;
//             set({
//                 prevIndex,
//                 selectedIndex,
//             })
//         },
//         setPrevIndex: prevIndex => set({prevIndex}),
//         setRenderedIndexes: renderedIndexes => set({renderedIndexes}),
//         setIsDragging: isDragging => set({isDragging}),
//         setBodyRef: bodyRef => set({bodyRef}),
//         setSliderRef: sliderRef => set({sliderRef})
//       }),
//       {
//         name: "navHome-store", // unique name
//       },
//     ),
//   )

export default navStore
