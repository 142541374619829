import numeral from "numeral"
import {ChangeEvent} from "react"
import {handleParseToTwoDecimal} from "screens/EOSPowerball/helper"
import {Odd} from "types/Powerball"
import ImageOptions from "types/ImageOptions"

export function setCaretPosition(ctrl, pos): void {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus()
    ctrl.setSelectionRange(pos, pos)

    // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange()
    range.collapse(true)
    range.moveEnd("character", pos)
    range.moveStart("character", pos)
    range.select()
  }
}

export function stripChar(char: string, word: string): string {
  return word.split(char).join("")
}

export function moveCursorToEnd(el: any): any {
  if (typeof el.selectionStart == "number") {
    el.selectionStart = el.selectionEnd = el.value.length
  } else if (typeof el.createTextRange != "undefined") {
    // el.focus()
    const range = el.createTextRange()
    range.collapse(false)
    range.select()
  }
}

export const tabElement = (e: any, next = true): any => {
  e.preventDefault && e.preventDefault()
  if ((e as any).code === "Enter") {
    const currentNode = e.target
    const allElements = (document as any).querySelectorAll("input, button")
    const currentIndex = [...allElements].findIndex(el =>
      currentNode.isEqualNode(el),
    )
    let targetIndex = next ? currentIndex + 1 : currentIndex - 1
    targetIndex = targetIndex % allElements.length
    allElements[targetIndex].focus()
    allElements[targetIndex].click()
  }
}

export const isValidDepositAmount = (amount: string, minValue: number): boolean => {
  if (amount === "") {
    return false
  }

  const newVal = isNaN(Number(amount))
    ? Number(amount?.split(",")?.join(""))
    : Number(amount)

  const isValidMin = newVal >= minValue
  const isValidDividend = newVal % 1000 === 0

  if (isValidMin && isValidDividend) {
    return true
  } else {
    return false
  }
}

export const formatAmount = (
  amount: string | number,
  forDisplay?: boolean,
): number | string => {
  const isNumber = typeof amount === "number"
  if (isNumber) return amount

  const newVal = Number(String(amount).replace(",", "").replace(",", ""))

  if (forDisplay) {
    return numeral(newVal).format("0,0")
  }

  return newVal
}

export const computeBetTypes = (betTypes: Odd[]) => {
  if (betTypes.length === 1) {
    return handleParseToTwoDecimal(Math.trunc(Number(betTypes[0].odds) * 100) / 100)
  } else if (betTypes.length > 1) {
    return "3.60"
    // const result: number = Number(betTypes[0].odds) * Number(betTypes[1].odds)
    // return handleParseToTwoDecimal(Math.trunc(result * 100) / 100)
  } else {
    return "0"
  }
}

export const computeWinningAmount = (amount: number, betTypes: Odd[]) => {
  let marketValue: number = betTypes.reduce(
    (acc, bet) => acc * Number(bet.odds || 1),
    1,
  )
  if (betTypes?.length > 1) {
    marketValue = 3.6
  }

  const truncated2Decimal = Math.trunc(marketValue * 100) / 100
  const result = amount * Number(truncated2Decimal)
  return Math.trunc(result)
}

export const formatWithdrawBalance = (balance: string, amount: string): string => {
  const newVal = isNaN(Number(amount))
    ? Number(amount?.split(",")?.join(""))
    : Number(amount)

  return numeral(Number(balance) - newVal).format("0,0")
}

export const scrollToTop = (): void => {
  window.scrollTo({top: 0, behavior: "smooth"})
}

export const returnTop = (): void => {
  // window.scrollTo({top: 1})
}

export const returnHomeTop = (page = "homepage"): void => {
  document.getElementById(`container-${page}`) &&
    document.getElementById(`container-${page}`).scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth",
    })
}

export const featureDenied = (hasPending = false): string => {
  if (hasPending) {
    return `진행중인 계좌등록/변경 건으로 인하여 인증완료후 진행해 주시기 바랍니다.`
  } else {
    return `계좌번호 인증이 필요한 컨텐츠 입니다.`
  }
}

export const hasPendingWithdrawAlert = (isWithdraw?: boolean): string => {
  return isWithdraw
    ? `현재 진행중인 환전 요청건이 있습니다.\n환전 완료후 변경 신청 해주시기 바랍니다.`
    : `현재 충전 진행 중에 있습니다.\n충전 완료 후 변경 신청해\n주시기 바랍니다.`
}

export function formatPhone(phone: string): string {
  return phone ? phone.replace(/(\d{3})(\d{4})(\d+)/, "$1-$2-$3") : ``
}

export function adjustPanelHeight(currScreenID: string): void {
  const aPage = document.getElementById(currScreenID) as HTMLElement
  const target = document.querySelector(`.eg-flick-viewport`) as HTMLElement

  if (target) {
    // target.style.height = `${aPage && aPage.getClientRects()[0].height}px`
  }
}

export function resizeNaverContainer(
  currentElement: HTMLDivElement,
  height?: number,
): void {
  const naverContainer: HTMLDivElement = document.querySelector(".eg-flick-viewport")

  naverContainer.style.height = `${height || currentElement.offsetHeight}px`
}

export function setHighlight(evt: any): void {
  const event = evt as any
  const tempBackgroundColor = evt.target.style.backgroundColor
  const tempTransition = evt.target.style.transition

  evt.target.style.transition = "none"

  event.target.style.backgroundColor = `rgba(0,0,0,0.1)`
  setTimeout(() => {
    event.target.style.backgroundColor = tempBackgroundColor
    evt.target.style.transition = tempTransition
  }, 100)
}

export function maskMobile(mobileNumber: string): string | null {
  if (mobileNumber === undefined) {
    return null
  }

  return (
    mobileNumber.substring(0, 3) +
    `-` +
    mobileNumber.substring(3, 7) +
    `-` +
    mobileNumber.substring(7, mobileNumber.length)
  )
}

export function maskMobileNumber(mobileNumber) {
  if (mobileNumber?.length !== 11) {
    return "***-****-****"
  }

  const maskedNumber =
    mobileNumber.substring(0, 3) +
    "-" +
    mobileNumber.charAt(3) +
    "***-" +
    mobileNumber.charAt(7) +
    "***"

  return maskedNumber
}

export function setToMilliseconds(time: string): number {
  if (time === undefined) {
    return
  }

  return Number(time.split(":")[0]) * 60 * 1000 + Number(time.split(":")[1]) * 1000
}

export function toTitleCase(str: string): string {
  return str?.replace(/(?:^|\s)\S/g, match => {
    return match?.toUpperCase()
  })
}

export function splitArray<T>(arr: Array<T>, len: number): Array<T[]> {
  if (arr === undefined) {
    return
  }

  const chunks = []
  let i = 0
  const n = arr.length
  while (i < n) {
    chunks.push(arr.slice(i, (i = i + len)))
  }
  return chunks
}

export const toNumbers = (arr: string[]): number[] => arr.map(Number)

export const removeDecimals = (value: number): number => Math.abs(Math.trunc(value))

export const addDefaultSrc = (ev: ChangeEvent<HTMLImageElement>): string => {
  return (ev.target.src = `/assets/img/placeholder_symbol/QM.png`)
}

export const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach(fn => fn && fn(...args))

export const numericTime = (timer = "05:00") => {
  const timerStr = timer.split(":").join("")

  return parseInt(timerStr)
}

export const formatDate = (date: Date) => {
  const dateStr = String(date)

  return `${dateStr.substring(0, 10)} - ${dateStr.substring(11, 19)}`
}

export const convertToKeyValues = options => {
  const keys = Object.keys(options)
  const values =
    keys &&
    keys
      .map(item => {
        const parsed = `${item}=${options[item]}`
        return parsed
      })
      .toString()

  return values
}

export const parseImageUrl = (url: string, options?: ImageOptions): string => {
  const env = process.env.REACT_APP_ENVIRONMENT
  const isLive = env === "secure" || env === "prod"
  const cfPrefix = `cdn-cgi/image`
  const isEz = window.location.host === "ezbet.com"
  const isEzBackup = window.location.host === "xn--hg3bn7s4tm.eezztv.com"
  const isCDN = isLive && !isEz && !isEzBackup

  const imgOptions = options ? `/${convertToKeyValues(options)}` : ""

  return isCDN ? `/${cfPrefix}${imgOptions}/${url}` : url
}

export const removeLeadingZerosRegex = str => {
  return str.replace(/^0+(?=\d)/, "")
}

export function maskCryptoId(text: string, maskLength = 10) {
  if (text.length < 20) {
    return text
  }
  const maskedText =
    text.substring(0, maskLength) +
    "***" +
    text.substring(text.length - maskLength, text.length)

  return maskedText
}
export function maskWalletAccount(text: string, maskLength = 10) {
  if (text) {
    const maskedText =
      text.substring(0, maskLength) +
      "***" +
      text.substring(text.length - maskLength, text.length)

    return maskedText
  } else {
    return ""
  }
}

export function isValidTronAddress(address) {
  return /^T[a-km-zA-HJ-NP-Z1-9]{33}$/.test(address)
}

export function isValidEthereumAddress(address) {
  return /^0x[a-fA-F0-9]{40}$/.test(address)
}
