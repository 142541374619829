import SlotStore from "screens/Slots/types/SlotsStore"
import create from "zustand"
import {persist} from "zustand/middleware"
/**
 * Types
 */

export const useSlotsStore = create<SlotStore>(
  // persist(
  set => ({
    isActive: false,
    isGameOpen: false,
    isDemoOpen: false,
    isGamesLoading: true,
    isGamesUrlLoading: true,
    isIFrameLoaded: false,
    isModalOpen: false,
    isRedirectLogin: false,
    gameCategories: [],
    selectedGame: null,
    gameUrl: null,
    demoUrl: null,
    gameId: null,
    sliderRef: null,
    closeGame: null,
    gameMode: "",
    setCloseGame: closeGame => set({closeGame}),
    setGameMode: gameMode => set({gameMode}),
    setGameCategories: gameCategories =>
      set(() => ({
        gameCategories,
      })),
    setIsActive: isActive => set({isActive}),
    setIsGameOpen: isGameOpen => set(() => ({isGameOpen})),
    setIsDemoOpen: isDemoOpen => set(() => ({isDemoOpen})),
    setIsGamesLoading: isGamesLoading => set(() => ({isGamesLoading})),
    setIsGamesUrlLoading: isGamesUrlLoading => set(() => ({isGamesUrlLoading})),
    setIsIFrameLoaded: isIFrameLoaded => set(() => ({isIFrameLoaded})),
    setIsModalOpen: isModalOpen => set(() => ({isModalOpen})),
    setIsRedirectLogin: isRedirectLogin => set({isRedirectLogin}),
    setSelectedGame: selectedGame =>
      set(() => ({
        selectedGame,
      })),
    setGameUrl: gameUrl => set(() => ({gameUrl})),
    setDemoUrl: demoUrl => set(() => ({demoUrl})),
    setGameId: gameId => set(() => ({gameId})),
    setSliderRef: sliderRef => set({sliderRef}),
  }),
  //   {
  //     name: "slot_games",
  //   },
  // ),
)
