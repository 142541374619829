import {useCallback, useEffect, useRef} from "react"
import navStore from "screens/NavHome/store/navStore"
import {useWindowSize} from "react-use"
import {
  LOGIN,
  FOOTER_PAGES_PATH,
  SIGNUP,
  FIND_ID,
  FIND_PASSWORD,
  PUBLIC_PATH_MAPPING,
  PRIVATE_PATH_MAPPING,
  PUBLIC_PAGES,
  NAV_HOME_SELECTED_PAGE,
  HOME,
  PROMOTIONS,
  FREE_GAMES,
  BET365,
  SKY_PARK,
  MY_PAGE,
  MAIN_PAGE_WITH_CODES,
  PUBLIC_PATHS,
} from "screens/NavHome/utils/config"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import {useUserStore} from "stores/userStore"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import useNavBarController from "screens/NavHome/hooks/useNavBarController"
import shallow from "zustand/shallow"
import useBet365NavBarProvider from "./useBet365NavBarProvider"
import useSkyParkNavBarProvider from "./useSkyParkNavBarProvider"
import useFreeGameNavBarProvider from "./useFreeGameNavBarProvider"
import useMyPageNavBarProvider from "./useMyPageNavBarProvider"
import useRechargeNavBarProvider from "./useRechargeNavBarProvider"
import {useLocation} from "react-router-dom"
import {CHARGE_EXCHANGE} from "screens/MyProfile/constants"

const STANDALONE_PAGES = [FREE_GAMES, BET365, SKY_PARK, MY_PAGE, CHARGE_EXCHANGE]

const useNavBarProvider = () => {
  useBet365NavBarProvider()
  useSkyParkNavBarProvider()
  useFreeGameNavBarProvider()
  useMyPageNavBarProvider()
  useRechargeNavBarProvider()
  const mounted = useRef(false)
  const location = useLocation()
  const urlPathDebounce = useRef<NodeJS.Timer>(null)
  const validatePathDebounce = useRef<NodeJS.Timer>(null)
  const [selectedSlideTitle, urlPath] = navStore(
    state => [state.selectedSlideTitle, state.urlPath],
    shallow,
  )
  const {navItems, handleClick, handleNavigateTo} = useNavBarController()
  const {width} = useWindowSize()
  const isTablet = width >= 674

  const authToken = useUserStore(state => state.authToken, shallow)

  const navToPublic = useCallback(
    (to: string) => {
      const authUser = useUserStore.getState().authToken

      if (authUser) return

      if (isTablet) {
        switch (to) {
          case SIGNUP:
            useRegisterModalStore.getState().setIsOpen(true)
            break
          case LOGIN:
            useLoginModalStore.getState().setIsOpen(true)
            break
          case FIND_ID:
            useLoginModalStore.getState().setSelectedTab(FIND_ID)
            useLoginModalStore.getState().setIsOpen(true)
            break
          case FIND_PASSWORD:
            useLoginModalStore.getState().setSelectedTab(FIND_PASSWORD)
            useLoginModalStore.getState().setIsOpen(true)
            break
          default:
            break
        }
      } else {
        setTimeout(() => handleNavigateTo(to), 300)
      }
    },
    [handleNavigateTo],
  )

  const validateReferral = useCallback((path: string) => {
    const authUser = useUserStore.getState().authToken
    const nav = navStore.getState()
    const code = nav.referralCode
    const paths = path.split("/")
    const isPublic = PUBLIC_PATHS.includes(paths[1]) && paths[1] !== "register"
    const isVisible = !isPublic && !authUser && code !== "ezbet"
    return  isVisible ? `${path}/${code}` : path
  },[])

  const validateUrlPath = useCallback(() => {
    const isFooter = FOOTER_PAGES_PATH.includes(window.location.pathname)
    const isRegisterOpen = useRegisterModalStore.getState().isOpen
    const isLoginOpen = useLoginModalStore.getState().isOpen
    const isModalOpen = isRegisterOpen || isLoginOpen
    if (isFooter || isModalOpen) return

    if (validatePathDebounce.current) clearTimeout(validatePathDebounce.current)

    validatePathDebounce.current = setTimeout(() => {
      const authUser = useUserStore.getState().authToken
      const nav = navStore.getState()
      const page = nav.selectedSlideTitle
      const PAGE_PATH_MAPPING = authUser ? PRIVATE_PATH_MAPPING : PUBLIC_PATH_MAPPING
      const keys = Object.keys(PAGE_PATH_MAPPING)
      const pageKey = keys.find(key => PAGE_PATH_MAPPING[key] === page)
      const fromFooter = FOOTER_PAGES_PATH.includes(nav.urlPath)
      const path = fromFooter ? nav.urlPath : pageKey

      if (path) {
        const pathName = validateReferral(path)
        window.history.replaceState(null, "", pathName)
      }
    }, 50)
  }, [])

  const updateUrlPath = useCallback((page: string) => {
    if (urlPathDebounce.current) clearTimeout(urlPathDebounce.current)

    urlPathDebounce.current = setTimeout(() => {
      const authUser = useUserStore.getState().authToken
      const PAGE_PATH_MAPPING = authUser ? PRIVATE_PATH_MAPPING : PUBLIC_PATH_MAPPING
      const keys = Object.keys(PAGE_PATH_MAPPING)
      const pageKey = keys.find(key => PAGE_PATH_MAPPING[key] === page)
      if (pageKey) {
        const pathName = validateReferral(pageKey)
        window.history.replaceState(null, "", pathName)
      }
    }, 50)
  }, [])

  const checkUrlPathAndSwipe = useCallback(() => {
    const nav = navStore.getState()
    const urlPath = window.location.pathname
    const isRegister = urlPath.includes("/register") && !urlPath.includes("/register-bank")
    const authUser = useUserStore.getState().authToken
    const PAGE_PATH_MAPPING = authUser ? PRIVATE_PATH_MAPPING : PUBLIC_PATH_MAPPING
    const page = PAGE_PATH_MAPPING[urlPath] ?? PROMOTIONS
    const items = page?.split("/")
    const hasSubPage = items?.length > 1
    const selectedPage = hasSubPage ? items[0] : page
    const cachedTitle = localStorage.getItem(NAV_HOME_SELECTED_PAGE)
    const parsedTitle =
      cachedTitle !== "undefined" && cachedTitle?.replace(/['"]+/g, "")
    let currentPage = selectedPage ? selectedPage : parsedTitle

    if(isRegister) currentPage = SIGNUP

    if (!currentPage) return

    const index = navItems.findIndex(item => item.title === currentPage)
    const isPublicPages = isRegister || PUBLIC_PAGES.includes(currentPage)

    if (isPublicPages) {
      nav.navToPublic(currentPage)
    } else if (index >= 0) {
      nav.setSelectedIndex(index)
      nav.setSliderIndex(index)
      nav.setSelectedTitle(currentPage)
      handleClick(currentPage)
    }
  }, [navItems, handleClick])

  useEffect(() => {
    navStore.getState().setValidateUrlPath(validateUrlPath)
  }, [validateUrlPath])

  useEffect(() => {
    navStore.getState().setNavToPublic(navToPublic)
  }, [navToPublic])
  useEffect(() => {
    navStore.getState().setUpdateUrlPath(updateUrlPath)
  }, [updateUrlPath])

  useEffect(() => {
    const nav = navStore.getState()
    const isLogout = authToken === null
    if (isLogout) {
      localStorage.removeItem(NAV_HOME_SELECTED_PAGE)
      localStorage.setItem("REFERRAL_CODE", "ezbet")
      nav.setSliderIndex(0)
      nav.setSelectedTitle(HOME)
      nav.setSelectedIndex(0)
      nav.setReferralCode("ezbet")
    }

    if(authToken) {
      nav.validateUrlPath()
      localStorage.setItem("REFERRAL_CODE", "ezbet")
      nav.setReferralCode("ezbet")
    }

  }, [authToken])

  useEffect(() => {
    const nav = navStore.getState()
    const page = nav.selectedSlideTitle
    const isStandalone = STANDALONE_PAGES.includes(nav.selectedSlideTitle)

    const isMounted = mounted.current
    if(!isMounted) {
      mounted.current = true
      const pathname = window.location.pathname
      const fromPublic = pathname === "/"
      if(!fromPublic) return
    }

    if (isStandalone) return

    nav.updateUrlPath(page)
  }, [selectedSlideTitle])

  useEffect(() => {
    const nav = navStore.getState()
    nav.setUrlPath(location?.pathname)
  }, [location?.pathname])

  useEffect(() => {
    const page = PUBLIC_PATH_MAPPING[urlPath]
    const isPublicPages = PUBLIC_PAGES.includes(page)
    const fromPublic = urlPath === "/"
    if (fromPublic || isPublicPages) {
      checkUrlPathAndSwipe()
    }
  }, [urlPath])

  useEffect(() => {
    const pathname = window.location.pathname
    const paths = pathname.split("/")
    const isFooter = FOOTER_PAGES_PATH.includes(`/${paths[1]}`)
    const isMainPages = MAIN_PAGE_WITH_CODES.includes(paths[1])
    
    if(isMainPages || isFooter) return
    
    checkUrlPathAndSwipe()
  }, [])
}

export default useNavBarProvider
