export const BETTING_RULES = {
  label: "footer.betting_rules.label",
  items: [
    {
      key: "PRE_MATCH_MANUAL",
      value: "footer.betting_rules.sports",
      path: "/betting-rules",
    },
  ],
}

export const EZ_BETTING_RULES = {
  label: "footer.betting_rules.label",
  items: [
    {
      key: "PRE_MATCH_MANUAL",
      value: "footer.betting_rules.sports",
      path: "/betting-rules",
      disabled: true,
    },
  ],
}

export const SERVICE_INFO = {
  label: "footer.service_info.label",
  items: [
    // {
    //   key: "LVL_MANUAL",
    //   value: "footer.service_info.member_lvl",
    //   path: "/membership-level",
    // },
    // {
    //   key: "GAME_MANUAL",
    //   value: "footer.service_info.game_guide",
    //   path: "/game-manual",
    // },
    // {
    //   key: "USER_MANUAL",
    //   value: "footer.service_info.site_guide",
    //   path: "/user-manual",
    // },
    {
      key: "DEPOSIT_WITHDRAW",
      value: "footer.service_info.deposit_withdraw_info",
      path: "/deposit-withdraw-info",
    },
  ],
}

export const TERMS_CONDITIONS = {
  label: "footer.terms_and_conditions.label",
  items: [
    {
      key: "TERMS_OF_USE",
      value: "footer.terms_and_conditions.terms_of_use",
      path: "/terms",
    },
    {
      key: "GAMING_CULTURE",
      value: "footer.terms_and_conditions.gaming_culture",
      path: "/gaming-culture",
    },
    {
      key: "PRIVACY_POLICY",
      value: "footer.terms_and_conditions.privacy_policy",
      path: "/privacy-policy",
    },
    {
      key: "AMLA",
      value: "footer.terms_and_conditions.amla",
      path: "/amla",
    },
  ],
}

export const EZ_TERMS_CONDITIONS = {
  label: "footer.terms_and_conditions.label",
  items: [
    {
      key: "TERMS_OF_USE",
      value: "footer.terms_and_conditions.terms_of_use",
      path: "/terms",
      disabled: true,
    },
    {
      key: "GAMING_CULTURE",
      value: "footer.terms_and_conditions.gaming_culture",
      path: "/gaming-culture",
      disabled: true,
    },
    {
      key: "PRIVACY_POLICY",
      value: "footer.terms_and_conditions.privacy_policy",
      path: "/privacy-policy",
      disabled: true,
    },
    {
      key: "AMLA",
      value: "footer.terms_and_conditions.amla",
      path: "/amla",
      disabled: true,
    },
  ],
}

export const BETTING_AND_SERVICE = {
  label: "footer.betting_and_service.label",
  items: [
    {
      key: "PRE_MATCH_MANUAL",
      value: "footer.betting_rules.pre_match",
      path: "/pre-match-betting",
    },
    {
      key: "LIVE_SPORTS_MANUAL",
      value: "footer.betting_rules.live",
      path: "/live-sports-betting",
    },
    {
      key: "LVL_MANUAL",
      value: "footer.service_info.member_lvl",
      path: "/membership-level",
    },
    {
      key: "GAME_MANUAL",
      value: "footer.service_info.game_guide",
      path: "/game-manual",
    },
    {
      key: "USER_MANUAL",
      value: "footer.service_info.site_guide",
      path: "/user-manual",
    },
  ],
}
