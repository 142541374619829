import { AFFILIATE, AFFILIATE_REGISTER, BET_HISTORY, CHANGE_BANK, CHANGE_PASSWORD, CRYPTO_WALLET, CUSTOMER_SUPPORT, INBOX, MOBILE_NUMBER, MONEY_HISTORY, MY_INFO, REGISTER_BANK, REWARD_HISTORY, SELF_RESTRICT, SMS_NOTIF } from "../MyProfile/constants"

type Level = {
  color: string
  ribbon: string
  name: string
  stamp: string
}

const TAB_PAGE_MY_INFORMATION = "my_information"
const TAB_PAGE_BETTING_HISTORY = "betting_history"
const TAB_PAGE_MONEY_HISTORY = "money_history"
const TAB_PAGE_REWARDS = "rewards"
const TAB_PAGE_ACCOUNT_CHANGE = "account_change"
const TAB_PAGE_REGISTER_BANK = "register_bank"
const TAB_PAGE_CRYPTO = "crypto"
const TAB_PAGE_PASSWORD = "password"
const TAB_PAGE_CELLPHONE = "cellphone"
const TAB_PAGE_SMS = "sms"
const TAB_PAGE_PROHIBIT = "prohibit"
const TAB_PAGE_CUSTOMER_SUPPORT = "customer_support"
const TAB_PAGE_INBOX = "inbox"
const TAB_PAGE_AFFILIATE = "affiliate"
const TAB_PAGE_CRYPTO_WALLETS = "crypto_wallets"
const TAB_PAGE_NONE = "none"

export const TabPage = {
  myInformation: TAB_PAGE_MY_INFORMATION,
  bettingHistory: TAB_PAGE_BETTING_HISTORY,
  moneyHistory: TAB_PAGE_MONEY_HISTORY,
  rewards: TAB_PAGE_REWARDS,
  accountChange: TAB_PAGE_ACCOUNT_CHANGE,
  registerBank: TAB_PAGE_REGISTER_BANK,
  // crypto: TAB_PAGE_CRYPTO,
  password: TAB_PAGE_PASSWORD,
  cellphone: TAB_PAGE_CELLPHONE,
  sms: TAB_PAGE_SMS,
  prohibit: TAB_PAGE_PROHIBIT,
  customerSupport: TAB_PAGE_CUSTOMER_SUPPORT,
  inbox: TAB_PAGE_INBOX,
  affiliate: TAB_PAGE_AFFILIATE,
  cryptoWallets: TAB_PAGE_CRYPTO_WALLETS,
  none: TAB_PAGE_NONE,
}

export const MOBILE_TAB_MAPPING = {
  [MY_INFO]: TAB_PAGE_MY_INFORMATION,
  [BET_HISTORY]: TAB_PAGE_BETTING_HISTORY,
  [MONEY_HISTORY]: TAB_PAGE_MONEY_HISTORY,
  [REWARD_HISTORY]: TAB_PAGE_REWARDS,
  [CHANGE_BANK]: TAB_PAGE_ACCOUNT_CHANGE,
  [REGISTER_BANK]: TAB_PAGE_REGISTER_BANK,
  [CHANGE_PASSWORD]: TAB_PAGE_PASSWORD,
  [MOBILE_NUMBER]: TAB_PAGE_CELLPHONE,
  [SMS_NOTIF]: TAB_PAGE_SMS,
  [SELF_RESTRICT]: TAB_PAGE_PROHIBIT,
  [CUSTOMER_SUPPORT]: TAB_PAGE_CUSTOMER_SUPPORT,
  [INBOX]: TAB_PAGE_INBOX,
  [AFFILIATE]: TAB_PAGE_AFFILIATE,
  [AFFILIATE_REGISTER]: TAB_PAGE_AFFILIATE,
  [CRYPTO_WALLET]: TAB_PAGE_CRYPTO_WALLETS
}

export const Levels: Record<string, Level> = {
  "bronze": {
    color: "#797979",
    ribbon: "/assets/img/mypage/Bronze-Ribbon.svg",
    name: "bronze",
    stamp: "/assets/img/mypage/Bronze-Stamp.png",
  },
  "silver": {
    color: "#797979",
    ribbon: "/assets/img/mypage/Silver-Ribbon.svg",
    name: "silver",
    stamp: "/assets/img/mypage/Silver-Stamp.png",
  },
  "gold": {
    color: "#FAC400",
    ribbon: "/assets/img/mypage/Gold-Ribbon.svg",
    name: "gold",
    stamp: "/assets/img/mypage/Gold-Stamp.png",
  },
  "diamond": {
    color: "#188C7A",
    ribbon: "/assets/img/mypage/Diamond-Ribbon.svg",
    name: "diamond",
    stamp: "/assets/img/mypage/Diamond-Stamp.png",
  },
  "vvip": {
    color: "#CCA4CA",
    ribbon: "/assets/img/mypage/VVIP-Ribbon.svg",
    name: "vvip",
    stamp: "/assets/img/mypage/VVIP-Stamp.png",
  },
}

export default Levels
