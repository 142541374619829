import {useCallback, useEffect} from "react"
import navStore from "screens/NavHome/store/navStore"
import {PUBLIC_PATH_MAPPING, SKY_PARK} from "screens/NavHome/utils/config"
import {useSkyparkStore} from "screens/SkyPark/store"
import shallow from "zustand/shallow"
import isEmpty from "lodash/isEmpty"

const useSkyParkNavBarProvider = () => {
  const {code, games, isLoadingGame} = useSkyparkStore(
    store => ({
      code: store.selectedGameDetails?.code,
      games: store.games,
      isLoadingGame: store.isLoadingGame
    }),
    shallow,
  )

  const skyparkSubPages = useCallback((to: string) => {
    const nav = useSkyparkStore.getState()
    const games = nav.games
    const selectedGame = Object.values(games).find(item => item.code === to);

    if (selectedGame) {
      nav.setSelectedGameDetails(selectedGame?.code)
    }
  }, [])
  
  useEffect(() => {
    const nav = navStore.getState()
    const isSkypark = nav.selectedSlideTitle === SKY_PARK

    if (isEmpty(games) || !isSkypark) return
    
    const urlPath = nav.urlPath
    const page = PUBLIC_PATH_MAPPING[urlPath]
    const items = page?.split("/")
    const hasSubPage = items?.length > 1
    
    if (hasSubPage) {
      skyparkSubPages(items[1])
    }
  }, [games])

  useEffect(() => {
    const nav = navStore.getState()
    const isSkypark = nav.selectedSlideTitle === SKY_PARK

    if (isEmpty(code) || isLoadingGame || !isSkypark) return

    const key = `${SKY_PARK}/${code}`
    nav.updateUrlPath(key)
  }, [code, isLoadingGame])
}

export default useSkyParkNavBarProvider
