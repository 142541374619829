import {useCallback, useEffect} from "react"
import navStore from "screens/NavHome/store/navStore"
import {PRIVATE_PATH_MAPPING, BET365} from "screens/NavHome/utils/config"
import {useBet365Store} from "screens/Bet365/store"
import {useUserStore} from "stores/userStore"
import shallow from "zustand/shallow"
import isEmpty from "lodash/isEmpty"

const useBet365NavBarProvider = () => {
  const {code, games, isGamesLoading} = useBet365Store(
    store => ({
      code: store.selectedGame?.code,
      games: store.games,
      isGamesLoading: store.isGamesLoading
    }),
    shallow,
  )

  const bet365SubPages = useCallback((to: string) => {
    const nav = useBet365Store.getState()
    const games = nav.games
    const selectedGame = games.find(item => item.code === to)

    if (selectedGame) {
      nav.setSelectedGame(selectedGame)
      nav.setSelectedleague(selectedGame?.leagues[0]?.code)
    }
  }, [])
  
  useEffect(() => {
    const authUser = useUserStore.getState().authToken
    const nav = navStore.getState()
    const isBet365 = nav.selectedSlideTitle === BET365

    if (isEmpty(games) || !authUser || !isBet365) return
    
    const urlPath = nav.urlPath
    const page = PRIVATE_PATH_MAPPING[urlPath]
    const items = page?.split("/")
    const hasSubPage = items?.length > 1

    if (hasSubPage) {
      bet365SubPages(items[1])
    }
  }, [games])

  useEffect(() => {
    const nav = navStore.getState()
    const isBet365 = nav.selectedSlideTitle === BET365

    if (isEmpty(code) || isGamesLoading || !isBet365) return

    const key = `${BET365}/${code}`
    nav.updateUrlPath(key)
  }, [code, isGamesLoading])
}

export default useBet365NavBarProvider
