import {
  BETTING_AND_SERVICE,
  BETTING_RULES,
  SERVICE_INFO,
  TERMS_CONDITIONS,
} from "../../../layout/Footer/config"
import {
  AFFILIATE,
  BET_HISTORY,
  CHANGE_BANK,
  CHANGE_PASSWORD,
  CHARGE_EXCHANGE,
  CRYPTO_WALLET,
  CUSTOMER_SUPPORT,
  DEPOSIT_APPLICATION,
  INBOX,
  MOBILE_NUMBER,
  MONEY_HISTORY,
  MY_INFO,
  RECHARGE_HISTORY,
  REGISTER_BANK,
  REWARD_HISTORY,
  SELF_RESTRICT,
  SMS_NOTIF,
  WITHDRAW_APPLICATION,
} from "../../MyProfile/constants"

export const HOME = "홈"
export const BTI_SPORTS = "BTI 스포츠"
export const LIVE_SPORTS = "라이브스포츠"
export const SPORTS = "스포츠"
export const LIVE_CASINO = "라이브카지노"
export const SLOTS = "슬롯"
export const MINI_GAMES = "파워볼"
export const MINI_GAMES_V2 = "EV 게임쇼"
export const BET365 = "BET365"
export const SKY_PARK = "스카이파크"
export const FREE_GAMES = "무료 게임"
export const PROMOTIONS = "이벤트"
export const MY_PAGE = "MY"
export const RECHARGE = "충전/환전"
export const SIGNUP = "회원가입"
export const LOGIN = "로그인"
export const FIND_ID = "아이디찾기"
export const FIND_PASSWORD = "비밀번호찾기"
export const NAV_HOME_SELECTED_PAGE = "navHomeSelectedPage"
export const SELECTED_PAGE = "prevSelectedPage"
export const CASINO_PATH = "live-casino"

export const HOME_PATH = "/"

export const PAGE_MAPPING = {
  "BTI 스포츠": "bti_sports",
  "스포츠": "sports",
  "라이브스포츠": "live_sports",
  "라이브카지노": "casino",
  "슬롯": "slots",
  // "파워볼": "mini_game",
  "EV 게임쇼": "mini_game_v2",
  "BET365": "bet365",
  "스카이파크": "skypark",
  "무료 게임": "free-games",
  "이벤트": "promotions",
  "MY": "mypage",
}

const COMMON_PATH_MAPPING = {
  "/home": HOME,
  "/sports": BTI_SPORTS,
  "/betting-rules": BTI_SPORTS,
  "/livecasino": LIVE_CASINO,
  "/slots": SLOTS,
  "/gameshows": MINI_GAMES_V2,
  "/freegame": FREE_GAMES,
  "/freegame/skypark/baccarat": `${FREE_GAMES}/baccarat`,
  "/freegame/skypark/dragon-tiger": `${FREE_GAMES}/dragontiger`,
  "/freegame/skypark/dice": `${FREE_GAMES}/dice`,
  "/skypark": SKY_PARK,
  "/skypark/baccarat": `${SKY_PARK}/baccarat`,
  "/skypark/dragon-tiger": `${SKY_PARK}/dragontiger`,
  "/skypark/samchigi": `${SKY_PARK}/samchigi`,
  "/skypark/odd-even": `${SKY_PARK}/oddeven`,
  "/skypark/speed-baccarat": `${SKY_PARK}/baccarat_speed`,
  "/skypark/dice": `${SKY_PARK}/dice`,
  "/skypark/sun-moon-star": `${SKY_PARK}/hdb`,
  "/events": PROMOTIONS,
}

export const PUBLIC_PATH_MAPPING = {
  ...COMMON_PATH_MAPPING,
  "/register": SIGNUP,
  "/login": LOGIN,
  "/find-id": FIND_ID,
  "/find-password": FIND_PASSWORD,
}

export const PRIVATE_PATH_MAPPING = {
  ...COMMON_PATH_MAPPING,
  "/bet365": BET365,
  "/bet365/soccer": `${BET365}/soccer`,
  "/bet365/basketball": `${BET365}/basketball`,
  "/bet365/greyhounds": `${BET365}/greyhounds`,
  "/bet365/horseracing": `${BET365}/horseracing`,
  "/bet365/baseball": `${BET365}/baseball`,
  "/freegame/bet365/soccer": `${FREE_GAMES}/soccer`,
  "/freegame/bet365/basketball": `${FREE_GAMES}/basketball`,
  "/mypage": MY_PAGE,
  "/mypage/my-information": `${MY_PAGE}/${MY_INFO}`,
  "/mypage/betting-history": `${MY_PAGE}/${BET_HISTORY}`,
  "/mypage/deposit-withdraw-history": `${MY_PAGE}/${RECHARGE_HISTORY}`,
  "/mypage/money-history": `${MY_PAGE}/${MONEY_HISTORY}`,
  "/mypage/mileage-history": `${MY_PAGE}/${REWARD_HISTORY}`,
  "/mypage/register-bank": `${MY_PAGE}/${REGISTER_BANK}`,
  "/mypage/change-bank": `${MY_PAGE}/${CHANGE_BANK}`,
  "/mypage/coin-wallet-registration": `${MY_PAGE}/CRYPTO_WALLET`,
  "/mypage/change-password": `${MY_PAGE}/${CHANGE_PASSWORD}`,
  "/mypage/change-mobile-number": `${MY_PAGE}/${MOBILE_NUMBER}`,
  "/mypage/sms-settings": `${MY_PAGE}/${SMS_NOTIF}`,
  "/mypage/self-restrict": `${MY_PAGE}/${SELF_RESTRICT}`,
  "/mypage/customer-center": `${MY_PAGE}/${CUSTOMER_SUPPORT}`,
  "/mypage/inbox": `${MY_PAGE}/${INBOX}`,
  "/mypage/partner": `${MY_PAGE}/${AFFILIATE}`,
  "/mypage/deposit": `${MY_PAGE}/CHARGE_EXCHANGE/${DEPOSIT_APPLICATION}`,
  "/mypage/deposit/cash": `${MY_PAGE}/CHARGE_EXCHANGE/${DEPOSIT_APPLICATION}/fiat`,
  "/mypage/deposit/crypto": `${MY_PAGE}/CHARGE_EXCHANGE/${DEPOSIT_APPLICATION}/crypto`,
  "/mypage/withdraw": `${MY_PAGE}/CHARGE_EXCHANGE/${WITHDRAW_APPLICATION}`,
  "/mypage/withdraw/cash": `${MY_PAGE}/CHARGE_EXCHANGE/${WITHDRAW_APPLICATION}/fiat`,
  "/mypage/withdraw/crypto": `${MY_PAGE}/CHARGE_EXCHANGE/${WITHDRAW_APPLICATION}/crypto`,
}

export const PUBLIC_PAGES = [SIGNUP, LOGIN, FIND_ID, FIND_PASSWORD]

export const FOOTER_PAGES_PATH = [
  ...BETTING_RULES.items.map(item => item.path),
  ...SERVICE_INFO.items.map(item => item.path),
  ...TERMS_CONDITIONS.items.map(item => item.path),
  ...BETTING_AND_SERVICE.items.map(item => item.path),
]

export const RECHARGE_PATHS = [
  "/mypage/deposit",
  "/mypage/deposit/cash",
  "/mypage/deposit/crypto",
  "/mypage/withdraw",
  "/mypage/withdraw/cash",
  "/mypage/withdraw/crypto",
  "/mypage/deposit-withdraw-history",
]

export const MAIN_PAGE_WITH_CODES = [
  "casino",
  "livecasino",
  "home",
  "sports",
  "slots",
  "gameshows",
  "skypark",
  "events",
  "freegame"
]

export const PUBLIC_PATHS = ["register", "login", "find-id", "find-password"]