import React, {FC, useEffect, useState} from "react"
import ReactModal from "react-modal"
import shallow from "zustand/shallow"
import cx from "classnames"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import navStore from "screens/NavHome/store/navStore"
import { SIGNUP } from "screens/NavHome/utils/config"
import s from "./modalRegister.module.scss"

interface iModalRegister {
  className?: string
  content: React.ReactNode
  hideModal: () => void
}

const ModalRegister: FC<iModalRegister> = ({
  className,
  content,
  hideModal,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const {handleOnExit, handleQuickExit, setClose, setIsOtpSent} =
    useRegisterModalStore(
      store => ({
        handleOnExit: store.handleOnExit,
        handleQuickExit: store.setIsOpen,
        setClose: store.setClose,
        setIsOtpSent: store.setIsOtpSent,
      }),
      shallow,
    )

  const handleClose = (): void => {
    const nav = navStore.getState()
    nav.validateUrlPath()

    setIsOpen(false)
    handleQuickExit(false)
    setIsOtpSent(false)
    setTimeout(() => {
      hideModal()
    }, 300)
  }

  useEffect(() => {
    setTimeout(() => {
      const nav = navStore.getState()
      nav.updateUrlPath(SIGNUP)
      setIsOpen(true)
    }, 50)
  }, [])

  useEffect(() => {
    setClose(handleClose)
  }, [handleClose])

  return (
    <ReactModal
      ariaHideApp={false}
      className={s.container}
      id={`register-modal`}
      isOpen
      overlayClassName={cx(s.overlay, {
        [s.show]: isOpen,
      })}
    >
      <div className={s.overlayBlanket} onClick={() => handleOnExit(handleClose)} />
      <div
        className={cx(s.modalWrapper, className, {
          [s.show]: isOpen,
        })}
      >
        {content}
      </div>
    </ReactModal>
  )
}

export default ModalRegister
