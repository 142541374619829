import React, {CSSProperties, FC, memo, Suspense, useContext} from "react"
import shallow from "zustand/shallow"
import cx from "classnames"
import {useWindowSize} from "react-use"
import {HOME, MY_PAGE} from "screens/NavHome/utils/config"
import useNavBarController from "screens/NavHome/hooks/useNavBarController"
import Logo from "screens/NavHome/components/Logo"
import NavList from "screens/NavHome/components/NavList"
import LoginBar from "screens/NavHome/components/LoginBar"
import {useUserStore} from "stores/userStore"
import navStore from "screens/NavHome/store/navStore"
import s from "screens/NavHome/components/NavBar/navBar.module.scss"
import {lazyWithRetry} from "utils/lazyWithRetry"
import {LiveChatIcon} from "./LiveChat/LiveChatIcon"
import useLiveChatStore from "components/LiveChat/useLiveChatStore"
import {ThemeContext} from "context/ThemeContext"
const SideBarButton = lazyWithRetry(() => import("components/SideBarButton"))
const Wallet = lazyWithRetry(() => import("components/Wallet"))
const InboxAlert = lazyWithRetry(
  () => import("screens/NavHome/components/InboxAlert"),
)
const GlobalAlert = lazyWithRetry(
  () => import("screens/NavHome/components/GlobalAlert"),
)

interface iNavBar {
  className?: string
  isExtraLarge?: boolean
  handleClick?: (label: string) => void
}

interface CSSCustomProperties extends CSSProperties {
  "--bg-color"?: string
  "--buttonActiveTextColor"?: string
  "--headerHoverColor"?: string
  "--buttonActivebottomLine"?: string
  "--headerActiveColor"?: string
}

const NavBar: FC<iNavBar> = ({className}): JSX.Element => {
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = {
    "--bg-color": theme?.colors.background,
    "--buttonActiveTextColor": theme?.colors.buttonActiveTextColor,
    "--headerHoverColor": theme?.colors.headerHoverColor,
    "--buttonActivebottomLine": theme?.colors.buttonActivebottomLine,
    "--headerActiveColor": theme?.colors.headerActiveColor,
  }

  const authToken = useUserStore(x => x.authToken, shallow)
  const toggleChat = useLiveChatStore(store => store.toggleChat, shallow)
  const {width} = useWindowSize()
  const {selectedTitle} = navStore(
    store => ({
      selectedTitle: store.selectedSlideTitle,
    }),
    shallow,
  )
  const {navItems, isLarge, handleNavigateTo} = useNavBarController()
  return (
    <>
      <Suspense fallback={<></>}>
        <GlobalAlert />
        <InboxAlert />
      </Suspense>
      <div className={cx(s.container, className)} style={custom_colors}>
        <Suspense fallback={<></>}>
          <div className={s.content}>
            {width < 842 && <SideBarButton />}
            <Logo handleClick={handleNavigateTo} selectedPage={selectedTitle} />
            {isLarge && (
              <NavList
                items={navItems
                  .filter(item => item.title !== HOME && item.title !== MY_PAGE)
                  .map(item => item.title)}
                handleClick={handleNavigateTo}
              />
            )}
            <div className={s.rightBar}>
              {authToken && (
                <Suspense fallback={<></>}>
                  <Wallet
                    className={cx(s.wallet, {[s.show]: selectedTitle !== MY_PAGE})}
                    selectedPage={selectedTitle}
                  />
                </Suspense>
              )}
              <LoginBar handleClick={handleNavigateTo} />
              {width > 1024 && (
                <div
                  className={s.liveChatBar}
                  onClick={() => {
                    toggleChat()
                  }}
                >
                  <LiveChatIcon />
                </div>
              )}
            </div>
          </div>
        </Suspense>
      </div>
    </>
  )
}

export default memo(NavBar)
