import React, {FC, useContext, useEffect} from "react"
import shallow from "zustand/shallow"
import ReactGA from "react-ga4"
import {useWindowSize} from "react-use"
import cx from "classnames"
import Banner from "components/RegisterButton/Banner"
import Success from "components/RegisterButton/Success"
import Form from "screens/Account/Register/component/Form"
import {Context as RegisterContext} from "screens/Account/Register/Context/Context"
import {iconClose} from "screens/Home/MyPage/Screens/BetHistory/components/BetDetails/icons"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import {LOGIN, SIGNUP} from "screens/NavHome/utils/config"
import R19 from "layout/Footer/icons/R19"
import s from "components/RegisterButton/Layout/layout.module.scss"
import {ThemeContext} from "context/ThemeContext"

const Layout: FC = () => {
  const {height} = useWindowSize()
  const {formSuccess} = useContext(RegisterContext)
  const {theme} = useContext(ThemeContext)
  const {setIsOtpSent, close, handleOnExit} = useRegisterModalStore(
    store => ({
      setIsOtpSent: store.setIsOtpSent,
      setIsOpen: store.setIsOpen,
      close: store.close,
      handleOnExit: store.handleOnExit,
    }),
    shallow,
  )

  const {setLoginOpen} = useLoginModalStore(
    store => ({
      setLoginOpen: store.setIsOpen,
    }),
    shallow,
  )

  const handleOpenlogin = () => {
    close()
    setTimeout(() => setLoginOpen(true), 200)
  }

  useEffect(() => {
    if (formSuccess) {
      setIsOtpSent(false)
      console.log("CreateUserAccount | success - label - ", SIGNUP)
      try {
        ReactGA.event({
          category: "CreateUserAccount",
          action: "User create an Account",
          label: SIGNUP,
        })
      } catch (err) {
        console.error(err)
      }
    }
  }, [formSuccess])

  const layoutHeight = height - 56 - 32

  useEffect(() => {
    try {
      console.log("PageView | User Navigate to Page - label - ", SIGNUP)
      ReactGA.event({
        category: "PageView",
        action: "User Navigate to Page",
        label: SIGNUP,
      })
    } catch (err) {
      console.error(err)
    }
  }, [])
  return (
    <div
      className={s.container}
      style={{
        height: `${layoutHeight}px`,
      }}
    >
      <div className={s.col1}>
        <Banner />
      </div>
      <div
        className={s.col2}
        style={{
          background: `url(${theme.images.register_BG})`,
          backgroundPosition: "top right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "70%",
        }}
      >
        <div className={s.closeContainer}>
          <button className={s.closeBtn} onClick={() => handleOnExit(close)}>
            {iconClose}
          </button>
        </div>
        <div className={cx(s.colBody, {[s.center]: formSuccess})}>
          {formSuccess ? (
            <Success handleOpenlogin={handleOpenlogin} />
          ) : (
            <>
              <div className={s.header}>
                <div className={s.headerLeft}>
                  <div className={s.title}>회원가입</div>
                  <div
                    className={s.loginLink}
                    onClick={() => handleOnExit(handleOpenlogin)}
                  >
                    {LOGIN}
                  </div>
                </div>
                <div className={s.ageRestrictContainer}>
                  <div className={s.text}>
                    <span>{`미성년자 가입불가 `}</span>
                    <span>{`05년 생일 지난 성인만 가능`}</span>
                  </div>
                  <R19 className={s.ageRestrict} />
                </div>
              </div>
              <Form className={s.form} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Layout
