import {
  CHARGE_EXCHANGE,
  MY_INFO,
  BET_HISTORY,
  RECHARGE_HISTORY,
  MONEY_HISTORY,
  REWARD_HISTORY,
  CHANGE_BANK,
  REGISTER_BANK,
  CHANGE_PASSWORD,
  MOBILE_NUMBER,
  SMS_NOTIF,
  SELF_RESTRICT,
  CUSTOMER_SUPPORT,
  INBOX,
  AFFILIATE,
  SIGN_OUT,
  TODAY,
  THREE_DAYS_AGO,
  WEEK,
  MONTH,
  DEPOSIT_APPLICATION,
  WITHDRAW_APPLICATION,
  CRYPTO_WALLET,
} from "screens/MyProfile/constants"
import {
  DepositWithdraw,
  MyInformation,
  BettingHistory,
  RechargeHistory,
  Bank,
  PasswordChange,
  CellphoneNumber,
  SmsNotification,
  SelfRestrict,
  CustomerSupport,
  Mail,
  HandShake,
  SignOut,
  SilverRibbon,
  GoldRibbon,
  DiamondRibbon,
  VVipRibbon,
  BronzeRibbon,
  CryptoWallet,
} from "screens/MyProfile/icons"
import BronzeBadge from "assets/MyProfile/badges/badge_bronze.png"
import SilverBadge from "assets/MyProfile/badges/badge_silver.png"
import GoldBadge from "assets/MyProfile/badges/badge_gold.png"
import DiamondBadge from "assets/MyProfile/badges/badge_diamond.png"
// import PlatinumBadge from "assets/MyProfile/badges/badge_platinum.png"
import VvipBadge from "assets/MyProfile/badges/badge_vvip.png"

const DepositWithdrawItem = {
  Icon: DepositWithdraw,
  label: CHARGE_EXCHANGE,
}

const MyInfoItem = {
  Icon: MyInformation,
  label: MY_INFO,
}

const BetHistoryItem = {
  Icon: BettingHistory,
  label: BET_HISTORY,
}

const RechargeHistoryItem = {
  Icon: RechargeHistory,
  label: RECHARGE_HISTORY,
}

const MoneyHistoryItem = {
  Icon: RechargeHistory,
  label: MONEY_HISTORY,
}

const RewardHistoryItem = {
  Icon: RechargeHistory,
  label: REWARD_HISTORY,
}

const ChangeBankItem = {
  Icon: Bank,
  label: CHANGE_BANK,
}

const CryptoWalletItem = {
  Icon: CryptoWallet,
  label: CRYPTO_WALLET,
}

const ChangePasswordItem = {
  Icon: PasswordChange,
  label: CHANGE_PASSWORD,
}

const MobilePhoneItem = {
  Icon: CellphoneNumber,
  label: MOBILE_NUMBER,
}

const NotificationItem = {
  Icon: SmsNotification,
  label: SMS_NOTIF,
}

const ProhibitItem = {
  Icon: SelfRestrict,
  label: SELF_RESTRICT,
}

const CustomerSupportItem = {
  Icon: CustomerSupport,
  label: CUSTOMER_SUPPORT,
}

const InboxItem = {
  Icon: Mail,
  label: INBOX,
}

const AffiliateItem = {
  Icon: HandShake,
  label: AFFILIATE,
}

const SignOutItem = {
  Icon: SignOut,
  label: SIGN_OUT,
}

export const SIDE_BAR_ITEMS = [
  DepositWithdrawItem,
  MyInfoItem,
  BetHistoryItem,
  RechargeHistoryItem,
  MoneyHistoryItem,
  RewardHistoryItem,
  ChangeBankItem,
  CryptoWalletItem,
  ChangePasswordItem,
  MobilePhoneItem,
  NotificationItem,
  ProhibitItem,
  CustomerSupportItem,
  InboxItem,
  AffiliateItem,
  SignOutItem,
]

export const DATE_FILTERS = [TODAY, THREE_DAYS_AGO, WEEK, MONTH]

export const Levels = {
  "bronze": {
    color: "##F2CCA6",
    subColor: "#989899",
    Ribbon: BronzeRibbon,
    name: "bronze",
    Stamp: BronzeBadge,
  },
  "silver": {
    color: "#d5d5d5",
    subColor: "#989899",
    Ribbon: SilverRibbon,
    name: "silver",
    Stamp: SilverBadge,
  },
  "gold": {
    color: "#FEE06C",
    subColor: "#A27229",
    Ribbon: GoldRibbon,
    name: "gold",
    Stamp: GoldBadge,
  },
  "diamond": {
    color: "#B1DED9",
    subColor: "#5E935D",
    Ribbon: DiamondRibbon,
    name: "diamond",
    Stamp: DiamondBadge,
  },
  "vvip": {
    color: "#D1CBE5",
    subColor: "#737E9E",
    Ribbon: VVipRibbon,
    name: "vvip",
    Stamp: VvipBadge,
  },
}

export const TabPage = {
  CHARGE_EXCHANGE: CHARGE_EXCHANGE,
  MY_INFO: MY_INFO,
  BET_HISTORY: BET_HISTORY,
  RECHARGE_HISTORY: RECHARGE_HISTORY,
  MONEY_HISTORY: MONEY_HISTORY,
  REWARD_HISTORY: REWARD_HISTORY,
  CHANGE_BANK: CHANGE_BANK,
  CRYPTO_WALLET: CRYPTO_WALLET,
  REGISTER_BANK: REGISTER_BANK,
  CHANGE_PASSWORD: CHANGE_PASSWORD,
  MOBILE_NUMBER: MOBILE_NUMBER,
  SMS_NOTIF: SMS_NOTIF,
  SELF_RESTRICT: SELF_RESTRICT,
  CUSTOMER_SUPPORT: CUSTOMER_SUPPORT,
  INBOX: INBOX,
  AFFILIATE: AFFILIATE,
}

export const REGEX_HANGUL = /(?=.*[가-힣])/g
export const REGEX_EN_LOWER = /(?=.*[a-z])/g
export const REGEX_EN_UPPER = /(?=.*[A-Z])/g
export const REGEX_NUMBER = /(?=.*[0-9])/g
export const REGEX_SPECIAL_CHARS = /(?=.*[$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-])/g
export const REGEX_ALPHA_NUM_SPECIAL_CHAR =
  /[^A-Za-z\d$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-]+/g
export const REGEX_HANGUL_ONLY = /[^\u3130-\u318F\uAC00-\uD7AF]/g
export const REGEX_PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?!=.*[가-힣])(?=.*\d)(?=.*[$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-]{1,}$/
export const REGEX_NUM_ONLY_PATTERN = /[^0-9]/g
export const REGEX_NUM_AND_DOT_ONLY_PATTERN = /[^.0-9]/g
export const REGEX_ALPHA_NUM_SPECIAL_CHAR_WITH_SPACE =
  /[^A-Za-z\d$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-\s]+/g
export const REGEX_HANGUL_ALPHA_NUM_SPECIAL_CHAR_WITH_SPACE =
  /[^A-Za-z\d$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-\s\u3130-\u318F\uAC00-\uD7AF]+/g
export const REGEX_EMAIL =
  /^[\w-\\.$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-]+@([\w-]+\.)+[\w-]{2,4}$/
export const REGEX_HANGUL_ALPHA_NUM = /[^A-Za-z\d\u3130-\u318F\uAC00-\uD7AF]+/g

export const DESKTOP_RECHARGE_TABS = [DEPOSIT_APPLICATION, WITHDRAW_APPLICATION]
export const MODAL_RECHARGE_TABS = [
  DEPOSIT_APPLICATION,
  WITHDRAW_APPLICATION,
  RECHARGE_HISTORY,
]
