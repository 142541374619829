import React, {FC, memo, useMemo, Suspense} from "react"
import shallow from "zustand/shallow"
import {handleGetRoutes, RECHARGE_CONFIG} from "screens/NavHome/utils/routes"
import navStore from "screens/NavHome/store/navStore"
import {useUserStore} from "stores/userStore"

const DesktopView: FC = (): JSX.Element => {
  const authToken = useUserStore(x => x.authToken, shallow)
  const {selectedTitle} = navStore(
    store => ({
      selectedTitle: store.selectedSlideTitle,
    }),
    shallow,
  )

  const PC_NAVER_ROUTES = useMemo(() => {
    const isEz = window.location.host === "ezbet.com"
    const routes = handleGetRoutes({
      authenticated: !!authToken,
      isEz,
      viewType: "pc",
    })
    return routes
  }, [authToken])

  const SelectedComponent = useMemo(() => {
    if (selectedTitle === RECHARGE_CONFIG.title) {
      return RECHARGE_CONFIG.component
    } else {
      const elem = PC_NAVER_ROUTES.filter(item => item.title === selectedTitle)[0]
        ?.component
      return elem
    }
  }, [selectedTitle, PC_NAVER_ROUTES])

  return (
    <Suspense fallback={<></>}>
      {SelectedComponent && <SelectedComponent />}
    </Suspense>
  )
}

export default memo(DesktopView)
