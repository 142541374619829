import React, {FC, useEffect, useState} from "react"
import ReactModal from "react-modal"
import shallow from "zustand/shallow"
import cx from "classnames"
import {LOGIN} from "components/LoginButton/contants"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import {useMinigameStore} from "stores/miniGameStore"
import navStore from "screens/NavHome/store/navStore"
import s from "./modalLogin.module.scss"

interface iModalLogin {
  className?: string
  content: React.ReactNode
  hideModal: () => void
}

const ModalLogin: FC<iModalLogin> = ({
  className,
  content,
  hideModal,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const gameOpen = useMinigameStore(store => store.activeTab !== 0, shallow)
  const {setSelectedTab, handleQuickExit, setClose} = useLoginModalStore(
    store => ({
      setSelectedTab: store.setSelectedTab,
      handleQuickExit: store.setIsOpen,
      setClose: store.setClose,
    }),
    shallow,
  )

  const handleClose = (): void => {
    const nav = navStore.getState()
    nav.validateUrlPath()
    setSelectedTab(LOGIN)
    setIsOpen(false)
    handleQuickExit(false)
    setTimeout(() => {
      hideModal()
    }, 300)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 50)
  }, [])

  useEffect(() => {
    setClose(handleClose)
  }, [handleClose])

  return (
    <ReactModal
      ariaHideApp={false}
      className={s.container}
      id={`login-modal`}
      isOpen
      overlayClassName={cx(s.overlay, {
        [s.show]: isOpen,
        [s.miniGamesOpen]: gameOpen,
      })}
    >
      <div className={s.overlayBlanket} onClick={() => handleClose()} />
      <div
        className={cx(s.modalWrapper, className, {
          [s.show]: isOpen,
        })}
      >
        {content}
      </div>
    </ReactModal>
  )
}

export default ModalLogin
