// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useState, FC} from "react"
/**
 * Service
 */
import service from "services/Account/Register"
/**
 * Interface
 */
import {iDetails} from "../Interface"
/**
 * Context
 */
import {Context} from "screens/Account/Register/Context/Context"
/**
 * Hooks
 */
import {useLocalStorage} from "utils/hooks/useLocalStorage"
/**
 * Utils
 */
import {stripChar} from "utils/helpers"
import {useModal} from "react-modal-hook"
import AlertModal from "components/ModalAlert"
import {isEmpty} from "lodash"
import navStore from "screens/NavHome/store/navStore"

const ProviderRegister: FC = ({children}) => {
  const [registerDetails, setRegisterDetails] = useState<iDetails>({
    usernameValid: false,
    username: "",
    password: "",
    password_confirm: "",
    name: "",
    mobile: "",
    referrer: "",
    captcha: "",
    terms: false,
    otp: "",
  })
  const [isInvalidReferrer, setInvalidReferrer] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [referrerSubmitting, setReferrerSubmitting] = useState(false)
  const [referrerCount, setReferrerCount] = useLocalStorage("referrerCount", 0)
  const [localTerms, setLocalTerms] = useState(false)
  const [localReferrer, setLocalReferrer] = useState("")
  const [localValidReferrer, setLocalValidReferrer] = useState("")
  const [delayTime, setDelayTime] = useState<number>(0)
  const [registerDelays, setRegisterDelays] = useState({
    username: 0,
    referrer: 0,
    isMobileExists: 0,
  })
  const [alertMessage, setAlertMessage] = useState<string>(null)
  const [showModal, hideModal] = useModal(
    () => (
      <AlertModal
        message={alertMessage}
        action={() => [hideModal(), setAlertMessage(null)]}
      />
    ),
    [alertMessage],
  )

  const category = "REGISTER_PLAYER"

  function setDetails(key: string, value: string | boolean) {
    return setRegisterDetails({
      ...registerDetails,
      [key]: value,
    })
  }

  function delayCall(remainingTime: number) {
    return remainingTime
  }

  async function checkDelays() {
    try {
      const responseUsername = (await service.checkDelay(
        "username",
        category,
      )) as any
      const responseReferrer = (await service.checkDelay(
        "referrer",
        category,
      )) as any
      const responseMobile = (await service.checkDelay("mobile", category)) as any

      const userData = responseUsername?.data
      const referrerData = responseReferrer?.data
      const mobileData = responseMobile?.data

      setRegisterDelays({
        username:
          userData?.username[0].remaining_time === null
            ? 0
            : userData?.username[0].remaining_time,
        referrer:
          referrerData?.referrable[0].remaining_time === null
            ? 0
            : referrerData?.referrable[0].remaining_time,
        isMobileExists:
          mobileData?.mobile[0].remaining_time === null
            ? 0
            : mobileData.mobile[0].remaining_time,
      })

      return {
        responseUsername,
        responseReferrer,
        responseMobile,
      }
    } catch (e) {
      return e
    }
  }

  async function handleReferrer(value: string): Promise<any> {
    try {
      const response = await service.checkReferrer(value)
      setInvalidReferrer(false)
      setReferrerSubmitting(false)
      return response
    } catch (e) {
      const eReturn = e?.data?.referrable[0]

      if (eReturn?.remaining_time !== null) {
        setDelayTime(eReturn?.remaining_time)
      }

      setInvalidReferrer(true)
      setReferrerCount(Number(eReturn?.count))
      setTimeout(() => {
        setReferrerSubmitting(false)
      }, 400)
      return e
    }
  }

  async function handleSubmitForm(): Promise<any> {
    const rd = registerDetails

    setFormSubmitting(true)

    try {
      const response = await service.registerUser({
        "g-recaptcha-response": rd?.captcha,
        "username": rd?.username,
        "password": rd?.password,
        "referrable": localReferrer,
        "terms": Boolean(localTerms),
        "name": rd?.name,
        "otp": rd?.otp,
        "mobile": stripChar("-", rd?.mobile),
      })
      setFormSuccess(true)
      setFormSubmitting(false)
      localStorage.removeItem("REFERRAL_CODE")
      return response
    } catch (e) {
      setFormSuccess(false)
      setFormSubmitting(false)
      // TODO: add error message translation in here
      const errorData = e.data
      const firstAttributeWithError = errorData[Object.keys(errorData)[0]]
      const firstErrorOnAttribute = firstAttributeWithError[0]?.message
      if (!isEmpty(firstErrorOnAttribute)) {
        setAlertMessage(firstErrorOnAttribute)
      } else if (!isEmpty(e.statusText)) {
        setAlertMessage(e.statusText)
      } else {
        setAlertMessage("Ooops something went wrong...")
      }

      showModal()
      return e
    }
  }

  function referrerCallback(value: string) {
    if (value === "") {
      return null
    }

    setReferrerSubmitting(true)
    setTimeout(
      () => handleReferrer(value),
      delayCall(
        registerDelays?.referrer !== 0 ? registerDelays?.referrer : delayTime,
      ),
    )
  }

  const handleCheckReferrer = () => {
    const code = localStorage.getItem("REFERRAL_CODE")
    if (code) {
      setLocalReferrer(code)
      referrerCallback(code)
    }
  }

  React.useEffect(() => {
    checkDelays()
    handleCheckReferrer()

    return () => {
      const nav = navStore.getState()
      nav.validateUrlPath()
    }
  }, [])

  return (
    <Context.Provider
      value={{
        formSuccess,
        formSubmitting,
        isInvalidReferrer,
        registerDetails,
        referrerSubmitting,
        localTerms,
        localReferrer,
        localValidReferrer,
        registerDelays,
        setDetails,
        setInvalidReferrer,
        setRegisterDetails,
        handleSubmitForm,
        referrerCallback,
        setLocalTerms,
        setLocalReferrer,
        setLocalValidReferrer,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ProviderRegister
