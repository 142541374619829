import create from "zustand"
import Flicking from "@egjs/react-flicking/declaration/Flicking"
import MiniGame from "screens/Home/MiniGame/types/MiniGame"
import {FreeGameDetails, gamesArrayData} from "screens/Home/FreeGames/types/FreeGame"

export const skyparkFreeGameCodes = ["dragontiger", "baccarat", "dice"]

export const defaultModalHeaderData = {
  virtual_balance: 0,
  has_prize: false,
  current_max_bet: 0,
  settings: {
    target_balance: 0,
    reward_amount: 0,
    end_time: "",
    start_time: "",
    max_bet: 0,
    min_bet: 0,
    is_downtime: false,
    max_winning_bet: 0,
  },
}
type FreegameVendorName = string | undefined

type Store = {
  activeTab: number
  activeGame: string
  activeGameId: number
  enableEvoPowerball: boolean
  openState: {
    ntryPowerball: boolean
    powerball: boolean
    powerLadder: boolean
  }
  details: MiniGame[]
  openTab: number
  isUpdating: boolean
  selected: string
  sliderRef: Flicking
  setIsUpdating: (value: boolean) => void
  setEnableEvoPowerball: (value: boolean) => void
  setSelected: (value: string) => void
  setOpenTab: (value: number) => void
  setActiveTab?: (arg: number) => void
  setNtryPowerballOpenState?: (arg: boolean) => void
  setPowerballOpenState?: (arg: boolean) => void
  clearOpenState: () => void
  setDetails: (value: MiniGame[]) => void
  setSliderRef: (value: Flicking) => void
  setActiveGame: (value: string) => void
  setActiveGameId: (value: number) => void
  gamesArrayData: gamesArrayData[]
  setGamesArrayData: (value: gamesArrayData[]) => void
  modalHeaderData: FreeGameDetails
  setModalHeaderData: (data: FreeGameDetails) => void
  freegameVendorName: FreegameVendorName
  setFreegameVendorName: (freegameVendorName: FreegameVendorName) => void
}

const initialState = {
  activeTab: 0,
  activeGame: "",
  activeGameId: 0,
  enableEvoPowerball: false,
  openState: {
    ntryPowerball: false,
    powerball: false,
    powerLadder: false,
  },
  openTab: 0,
  isUpdating: false,
  selected: "baccarat",
  details: [],
  sliderRef: null,
  gamesArrayData: [],
  modalHeaderData: defaultModalHeaderData,
  freegameVendorName: undefined,
}

export const useFreeGameStore = create<Store>((set, get) => ({
  ...initialState,
  setEnableEvoPowerball: enableEvoPowerball => set({enableEvoPowerball}),
  setActiveTab: (newTab: number) => set({activeTab: newTab}),
  setNtryPowerballOpenState: (val: boolean) =>
    set({
      openState: {
        ...get().openState,
        ntryPowerball: val,
      },
    }),
  setPowerballOpenState: (val: boolean) => {
    set({
      openState: {
        ...get().openState,
        powerball: val,
      },
    })
  },
  clearOpenState: () => set({openState: initialState.openState}),
  setDetails: (value: MiniGame[]) => set({details: value}),
  setOpenTab: openTab => set({openTab}),
  setActiveGame: activeGame => {
    set({activeGame})
  },
  setSelected: selected => set({selected}),
  setIsUpdating: isUpdating => set({isUpdating}),
  setSliderRef: sliderRef => set({sliderRef}),
  setGamesArrayData: (value: gamesArrayData[]) => set({gamesArrayData: value}),
  setModalHeaderData: (data: FreeGameDetails) => set({modalHeaderData: data}),
  setActiveGameId: (value: number) => set({activeGameId: value}),
  setFreegameVendorName: (freegameVendorName: FreegameVendorName) =>
    set({freegameVendorName}),
}))
