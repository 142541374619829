import {useCallback, useMemo} from "react"
import {useHistory} from "react-router-dom"
import navStore from "screens/NavHome/store/navStore"
import shallow from "zustand/shallow"
import {useWindowSize} from "react-use"
import usePageTransition from "utils/hooks/usePageTransition"
import {
  HOME_PATH,
  NAV_HOME_SELECTED_PAGE,
  MY_PAGE,
  RECHARGE,
  LOGIN,
  FOOTER_PAGES_PATH,
  PRIVATE_PATH_MAPPING,
  PUBLIC_PATH_MAPPING,
} from "screens/NavHome/utils/config"
import {handleGetRoutes} from "screens/NavHome/utils/routes"
import {CUSTOMER_SUPPORT, BET_HISTORY} from "screens/MyProfile/constants"
import {TabPage} from "screens/MyPage/config"
import useBreakpoint from "hooks/useBreakpoints"
import NavBodyType from "screens/NavHome/types/NavBody"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import useBeticaStore from "stores/useBeticaStore"
import useSideBarStore from "components/SideBarButton/hooks/useSideBarStore"
import useMyProfileStore from "screens/MyProfile/store/useMyProfileStore"
import {useMyPageStore} from "screens/MyPage/store"
import {useUserStore} from "stores/userStore"
import useFooterStore from "stores/Footer/useFooterStore"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import useWalletStore from "components/Wallet/hooks/useWalletStore"
import useNewsStore from "screens/News/stores/useNewsStore"
import {useMinigameStore} from "stores/miniGameStore"

interface iNavBarController {
  navItems: NavBodyType[]
  isLarge: boolean
  handleClick: (value: string, from?: string) => void
  handleNavigateTo: (value: string, from?: string) => void
  doNavigate: (value: string, index: number) => void
  handlePageTransition: (callback: () => void) => void
  navToCustomerSupport: () => void
  navToBetHistory: () => void
  redirectToLogin: () => void
  openDeposit: (from?: string) => void
}

const useNavBarController = (): iNavBarController => {
  const setSelectedFooter = useFooterStore(store => store.setSelected, shallow)
  const authToken = useUserStore(store => store.authToken, shallow)
  const history = useHistory()
  const {width} = useWindowSize()
  const isTablet = width >= 674
  const isLarge = useBreakpoint("(min-width: 1000px)")
  const {handlePageTransition} = usePageTransition()
  const hasWallet = useMemo(() => width > 674, [width])

  const NAV_CONTENT = useMemo(() => {
    const isEz = window.location.host === "ezbet.com"

    const routes = handleGetRoutes({
      authenticated: !!authToken,
      isEz,
      viewType: isLarge ? "pc" : isTablet ? "tablet" : "mobile",
    })
    return routes
  }, [isLarge, authToken, isTablet])

  const setRedirectTo = useSideBarStore(store => store.setRedirectTo, shallow)
  const {setBeticaUrl} = useBeticaStore(
    store => ({
      setBeticaUrl: store.setGameUrl,
    }),
    shallow,
  )

  const {handleCheckOpenRegister} = useRegisterModalStore(
    store => ({
      handleCheckOpenRegister: store.handleCheckOpen,
    }),
    shallow,
  )

  const {setSelectedTitle, setSelectedIndex, setSliderIndex} = navStore(
    store => ({
      setSelectedTitle: store.setSelectedTitle,
      setSelectedIndex: store.setSelectedIndex,
      setSliderIndex: store.setSliderIndex,
    }),
    shallow,
  )

  const [setIsNewsOpen] = useNewsStore(store => [store.setIsOpen], shallow)

  const doNavigate = useCallback(
    (label: string, index: number) => {
      setSelectedIndex(index)
      localStorage.setItem(NAV_HOME_SELECTED_PAGE, label)
    },
    [setSelectedIndex],
  )

  const handleSetHomePath = (page: string) => {
    const authUser = useUserStore.getState().authToken
    const PAGE_PATH_MAPPING = authUser ? PRIVATE_PATH_MAPPING : PUBLIC_PATH_MAPPING
    const keys = Object.keys(PAGE_PATH_MAPPING)
    const pageKey =  keys.find(key => PAGE_PATH_MAPPING[key] === page)
    history.push(pageKey)
  }

  const handleClickMobile = useCallback(
    (label: string, index: number): void => {
      const notHome = FOOTER_PAGES_PATH.includes(window.location.pathname)
      const delay = notHome ? 50 : 0
      !notHome && setSliderIndex(index)
      handlePageTransition(() => {
        notHome && handleSetHomePath(label)
        !notHome && setSelectedTitle(label)
        setTimeout(() => {
          notHome && setSliderIndex(index)
          notHome && setSelectedTitle(label)
          setSelectedFooter(null)
          doNavigate(label, index)
          setBeticaUrl(null)
          setIsNewsOpen(false)
        }, delay)
      })
    },
    [setSliderIndex, setSelectedTitle, doNavigate, NAV_CONTENT],
  )

  const handleClickPC = useCallback(
    (label: string, index: number): void => {
      const notHome = FOOTER_PAGES_PATH.includes(window.location.pathname)
      const delay = notHome ? 50 : 0


      handlePageTransition(() => {
        notHome && handleSetHomePath(label)
        setTimeout(() => {
          setSelectedFooter(null)
          setSelectedTitle(label)
          doNavigate(label, index)
          setBeticaUrl(null)
          setIsNewsOpen(false)
        }, delay)
      })
    },
    [setBeticaUrl, setSelectedTitle, doNavigate, NAV_CONTENT],
  )

  const handleClick = useCallback(
    (label: string, from?: string): void => {
      const index = NAV_CONTENT.map(item => item.title).indexOf(label)
      isLarge
        ? handleCheckOpenRegister(() => handleClickPC(label, index))
        : handleClickMobile(label, index)

      if (from !== "sidebar") setRedirectTo(null)
    },
    [
      isLarge,
      NAV_CONTENT,
      handleClickPC,
      handleClickMobile,
      handleCheckOpenRegister,
    ],
  )

  const redirectToLogin = useCallback(() => {
    if (isTablet) {
      const login = useLoginModalStore.getState()
      login.setIsOpen(true)
    } else {
      const notHome = FOOTER_PAGES_PATH.includes(window.location.pathname)
      const delay = notHome ? 50 : 0
      handlePageTransition(() => {
        notHome && history.push(HOME_PATH)
        setTimeout(() => {
          handlePageTransition(() => {
            setSelectedTitle(LOGIN)

            // close powerball
            const mini = useMinigameStore.getState()
            mini.setActiveTab(0)
          })
        }, delay)
      })
    }
  }, [isTablet])

  const handleNavigateTo = useCallback(
    (to: string, from?: string) => {
      const index = NAV_CONTENT.map(item => item.title).indexOf(to)
      if (index >= 0) {
        handleClick(to, from)
      } else {
        const notHome = FOOTER_PAGES_PATH.includes(window.location.pathname)
        const delay = notHome ? 50 : 0

        handlePageTransition(() => {
          notHome && handleSetHomePath(to)
          setTimeout(() => {
            if (notHome) {
              handlePageTransition(() => {
                setSelectedFooter(null)
                setSelectedTitle(to)
                setIsNewsOpen(false)
                from === "sports" && handleSetHomePath(to)
              })
            } else {
              setSelectedFooter(null)
              setSelectedTitle(to)
              setIsNewsOpen(false)
              from === "sports" && handleSetHomePath(to)
            }
          }, delay)
        })
      }
    },
    [handleClick, NAV_CONTENT],
  )

  const toCustomerSupportTablet = useCallback(() => {
    const myProfile = useMyProfileStore.getState()
    const isMyPage = MY_PAGE === navStore.getState().selectedSlideTitle
    if (!isMyPage) handleNavigateTo(MY_PAGE)
    myProfile.setSelectedTab(CUSTOMER_SUPPORT)
  }, [handleNavigateTo])

  const toCustomerSupportonMobile = useCallback(() => {
    const myPage = useMyPageStore.getState()
    handleNavigateTo(MY_PAGE)
    myPage.setActiveTab(TabPage.customerSupport)
  }, [handleNavigateTo])

  const navToCustomerSupport = useCallback(() => {
    const token = useUserStore.getState()?.authToken

    if (!token) return redirectToLogin()

    if (isTablet) toCustomerSupportTablet()
    else toCustomerSupportonMobile()
  }, [isTablet, toCustomerSupportTablet, toCustomerSupportonMobile])

  const toBetHistoryTablet = useCallback(() => {
    const myProfile = useMyProfileStore.getState()
    const isMyPage = MY_PAGE === navStore.getState().selectedSlideTitle
    if (!isMyPage) handleNavigateTo(MY_PAGE)
    myProfile.setSelectedTab(BET_HISTORY)
  }, [handleNavigateTo])

  const toBetHistoryMobile = useCallback(() => {
    const myPage = useMyPageStore.getState()
    handleNavigateTo(MY_PAGE)
    myPage.setActiveTab(TabPage.bettingHistory)
  }, [handleNavigateTo])

  const navToBetHistory = useCallback(() => {
    if (isTablet) toBetHistoryTablet()
    else toBetHistoryMobile()
  }, [isTablet, toBetHistoryMobile, toBetHistoryTablet])

  const openWallet = () => {
    const wallet = useWalletStore.getState()
    wallet.setIsOpen(true)
  }

  const openDeposit = useCallback(
    (from?: string) => {
      if (hasWallet) openWallet()
      else {
        if (from === "footer") {
          handlePageTransition(() => {
            history.push(HOME_PATH)
            setTimeout(() => {
              handleClick(RECHARGE)
            }, 50)
          })

          return
        }

        handleClick(RECHARGE)
      }
    },
    [hasWallet, handleClick, openWallet],
  )

  return {
    navItems: NAV_CONTENT,
    isLarge,
    handleClick,
    handleNavigateTo,
    doNavigate,
    handlePageTransition,
    navToCustomerSupport,
    navToBetHistory,
    redirectToLogin,
    openDeposit,
  }
}

export default useNavBarController
