import React, {FC, memo, useEffect, useRef} from "react"
import cx from "classnames"
import shallow from "zustand/shallow"
import {iconClose} from "screens/Home/MyPage/Screens/BetHistory/components/BetDetails/icons"
import {useWindowSize} from "react-use"
import s from "./CloseTimerButton.module.scss"
import usePopupBannerStore from "components/Banner/PopupV4/usePopupBannerStore"
import ExitButton from "../icon/ExitButton"
import PieChart from "../../../PieChart"

interface iCloseTimerButton {
  className?: string
}

const CloseTimerButton: FC<iCloseTimerButton> = ({className}): JSX.Element => {
  const {width} = useWindowSize()
  const isMobile = width < 674
  const tickRef = useRef<NodeJS.Timeout>()
  const [isClosing, closeTimer, timerTick, close] = usePopupBannerStore(
    store => [store.isClosing, store.closeTimer, store.timerTick, store.close],
    shallow,
  )

  useEffect(() => {
    if (!isClosing) return clearInterval(tickRef.current)
    tickRef.current = setInterval(() => {
      timerTick()
    }, 1000)
  }, [isClosing])

  if (isMobile)
    return (
      <div className={cx(s.actionBar, className)}>
        {isClosing ? (
          <PieChart
            className={s.countDown}
            strokeWidth={2}
            value={closeTimer}
            totalValue={2}
            size={32}
            containerColor={"#ffffff80"}
            progressColor={"#fff"}
          />
        ) : (
          <div role="button" onClick={() => close()} className={s.icon}>
            <ExitButton />
          </div>
        )}
      </div>
    )
  return (
    <div className={cx(s.closeContainer, className)}>
      {isClosing ? (
        <PieChart
          className={s.countDown}
          strokeWidth={2}
          value={closeTimer}
          totalValue={2}
          size={44}
          containerColor={"#ffffff80"}
          progressColor={"#fff"}
        />
      ) : (
        <button className={s.closeBtn} onClick={() => close()}>
          {iconClose}
        </button>
      )}
    </div>
  )
}

export default memo(CloseTimerButton)
