import create from "zustand"
import {persist} from "zustand/middleware"
import {DEPOSIT_APPLICATION} from "screens/MyProfile/constants"
import {iWalletStore} from "components/Wallet/hooks/useWalletStore"

const useRechargeStore = create<iWalletStore>(
  persist(
    (set, get) => ({
      selectedTab: DEPOSIT_APPLICATION,
      setSelectedTab: selectedTab => set({selectedTab}),

      method: null,
      setMethod: method => set({method})
    }),
    {
      name: "Recharge",
    },
  ),
)

export default useRechargeStore
