// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {Suspense, memo, useMemo, useState, useLayoutEffect} from "react"
import {useLocation} from "react-router-dom"
import shallow from "zustand/shallow"
import {useWindowSize} from "react-use"
import cx from "classnames"

import useBeticaStore from "stores/useBeticaStore"
import {useMinigameStore} from "stores/miniGameStore"
import useFooterStore from "stores/Footer/useFooterStore"
import s from "screens/NavHome/navHome.module.scss"
import {lazyWithRetry} from "utils/lazyWithRetry"
import navStore from "screens/NavHome/store/navStore"
import {FOOTER_PAGES_PATH, PUBLIC_PAGES} from "screens/NavHome/utils/config"
import {useFreeGameStore} from "stores/freeGameStore"
import {useHomeV2Store} from "stores/homeV2store"
import NavBar from "screens/NavHome/components/NavBar"
import NavSlider from "screens/NavHome/components/NavSlider"

const Header = () => {
  const [isMounted, setIsMounted] = useState(false)
  const {width} = useWindowSize()
  const location = useLocation()
  const {selectedSlideTitle} = navStore(
    store => ({
      selectedSlideTitle: store.selectedSlideTitle,
    }),
    shallow,
  )
  const footerActive = useFooterStore(store => store.selected !== null, shallow)
  const {isBeticaOpen, isBeticaBetSlipOpen, isBetpSlipMounted} = useBeticaStore(
    store => ({
      isBeticaOpen: store.gameOpen,
      isBeticaBetSlipOpen: store.isBetSlipOpen,
      isBetpSlipMounted: store.isBetpSlipMounted,
    }),
    shallow,
  )

  const {openedGame} = useMinigameStore(
    store => ({
      openedGame: store.openTab,
    }),
    shallow,
  )
  const activeFreeGame = useFreeGameStore(store => store.activeGame, shallow)
  const activeHomeGame = useHomeV2Store(store => store.activeGame, shallow)

  const hasBetSlip = useMemo(() => {
    return width <= 842
  }, [width])

  const hasSlider = useMemo(() => {
    const isMobile = width < 1000
    const isHome = !PUBLIC_PAGES.includes(selectedSlideTitle)
    const isFooter = FOOTER_PAGES_PATH.includes(location.pathname)
    return isMobile && isHome && !footerActive && !isFooter
  }, [footerActive, width, selectedSlideTitle, location.pathname])

  useLayoutEffect(() => {
    if (!hasSlider) return
    setTimeout(() => {
      setIsMounted(true)
    }, 100)
  }, [hasSlider])

  return (
    <div
      className={cx(s.navContainer, {
        [s.hide]:
          activeHomeGame.provider !== "" ||
          openedGame !== 0 ||
          activeFreeGame !== "",
        [s.hideForFreeGames]: width > 842 && activeFreeGame !== "", // on desktop hide header completely
        [s.isBeticaOpen]: hasBetSlip && isBeticaOpen,
        [s.openBetSlip]: hasBetSlip && isBeticaBetSlipOpen,
        [s.closeBetSlip]:
          isBetpSlipMounted && isBeticaOpen && hasBetSlip && !isBeticaBetSlipOpen,
        [s.preventScroll]: selectedSlideTitle === "홈",
      })}
    >
      <Suspense fallback={<></>}>
        <NavBar className={s.navBar} />

        {hasSlider && isMounted && <NavSlider className={s.navSlider} />}
      </Suspense>
    </div>
  )
}

export default memo(Header)
