import {useCallback, useEffect} from "react"
import navStore from "screens/NavHome/store/navStore"
import {
  PRIVATE_PATH_MAPPING,
  MY_PAGE,
  RECHARGE_PATHS,
} from "screens/NavHome/utils/config"
import useMyProfileStore from "screens/MyProfile/store/useMyProfileStore"
import useRechargeStore from "screens/MyProfile/store/useRechargeStore"
import {useUserStore} from "stores/userStore"
import shallow from "zustand/shallow"
import isEmpty from "lodash/isEmpty"
import {CHARGE_EXCHANGE, RECHARGE_HISTORY} from "../../MyProfile/constants"
import {useWindowSize} from "react-use"
import useNavBarController from "screens/NavHome/hooks/useNavBarController"

const useRechargeNavBarProvider = () => {
  const {navItems, handleClick} = useNavBarController()
  const {width} = useWindowSize()
  const isMobile = width < 674
  const [selectedSlideTitle] = navStore(state => [state.selectedSlideTitle], shallow)
  const {tab} = useMyProfileStore(
    store => ({
      tab: store.selectedTab,
    }),
    shallow,
  )

  const {rechargeTab, method} = useRechargeStore(
    store => ({
      rechargeTab: store.selectedTab,
      method: store.method,
    }),
    shallow,
  )

  const initRechargeTab = useCallback(
    (subTab?: string, method?: string) => {
      if (subTab) {
        const innerNav = useRechargeStore.getState()
        innerNav.setSelectedTab(subTab)
      }

      const innerNav = useRechargeStore.getState()
      innerNav.setMethod(method)

      if (isMobile) {
        const index = navItems.findIndex(item => item.title === CHARGE_EXCHANGE)
        const nav = navStore.getState()
        nav.setSelectedIndex(index)
        nav.setSliderIndex(index)
        nav.setSelectedTitle(CHARGE_EXCHANGE)
        handleClick(CHARGE_EXCHANGE)
      } else {
        const nav = useMyProfileStore.getState()
        nav.setSelectedTab(CHARGE_EXCHANGE)
      }
    },
    [navItems, handleClick, isMobile],
  )

  //PC Withdraw - Deposit
  useEffect(() => {
    const authUser = useUserStore.getState().authToken
    const nav = navStore.getState()
    const isMyPage = nav.selectedSlideTitle === MY_PAGE
    if (!isMyPage || !authUser || isMobile) return

    const urlPath = nav.urlPath
    const page = PRIVATE_PATH_MAPPING[urlPath]
    const items = page?.split("/")
    const tab = items && items[1]
    const isRecharge = tab === "CHARGE_EXCHANGE"

    if (isRecharge) initRechargeTab(items[2], items[3])
  }, [selectedSlideTitle, isMobile])

  //PC Withdraw - Deposit
  useEffect(() => {
    const nav = navStore.getState()
    const isMyPage = nav.selectedSlideTitle === MY_PAGE
    const isRecharge = tab === CHARGE_EXCHANGE
    if (!isMyPage || isEmpty(tab) || !isRecharge || isMobile) return

    const currentTab = tab.replace(CHARGE_EXCHANGE, "CHARGE_EXCHANGE")
    const innerNav = useRechargeStore.getState()
    const method = innerNav.method ? `/${innerNav.method}` : ""
    const key = `${MY_PAGE}/${currentTab}/${innerNav.selectedTab}${method}`
    nav.updateUrlPath(key)
  }, [tab, selectedSlideTitle, rechargeTab, method, isMobile])

  //Mobile Withdraw - Deposit
  useEffect(() => {
    const authUser = useUserStore.getState().authToken
    const nav = navStore.getState()
    const isRechargePath = RECHARGE_PATHS.includes(nav.urlPath)
    const isMyPage = nav.selectedSlideTitle === MY_PAGE && !isRechargePath
    if (isMyPage || !authUser || !isMobile) return

    const urlPath = nav.urlPath
    const page = PRIVATE_PATH_MAPPING[urlPath]
    const items = page?.split("/")
    const tab = items && items[1]
    const isRecharge = tab === "CHARGE_EXCHANGE"
    const isHistory = items && items[1] === RECHARGE_HISTORY

    if(isHistory)
      initRechargeTab(RECHARGE_HISTORY)
    else if (isRecharge)
      initRechargeTab(items[2], items[3])
  }, [selectedSlideTitle, isMobile])

  //Mobile Withdraw - Deposit
  useEffect(() => {
    const isRecharge = selectedSlideTitle === CHARGE_EXCHANGE
    const isMyPage = selectedSlideTitle === MY_PAGE
    const isHistory = RECHARGE_HISTORY === rechargeTab
    if (!isRecharge || isMyPage || isHistory) return
  
    const innerNav = useRechargeStore.getState()
    const method = innerNav.method ? `/${innerNav.method}` : ""
    const key = `${MY_PAGE}/CHARGE_EXCHANGE/${innerNav.selectedTab}${method}`
    const nav = navStore.getState()
    nav.updateUrlPath(key)
  }, [rechargeTab, method, selectedSlideTitle])

  useEffect(() => {
    const isHistory = RECHARGE_HISTORY === rechargeTab
    if (!isHistory) return
    
    const key = `${MY_PAGE}/${RECHARGE_HISTORY}`
    const nav = navStore.getState()
    nav.updateUrlPath(key)

  }, [rechargeTab])
}

export default useRechargeNavBarProvider
