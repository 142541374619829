import create from "zustand"
import {persist} from "zustand/middleware"
import {UserData} from "types/App/user"
import {setCookie} from "utils/hooks/useCookieStorage"
type Store = {
  userDetails: UserData | null
  expiresIn: number
  usernameUnmasked: string
  setUsernameUnmasked: (usernameUnmasked: string) => void
  setUserDetails: (userDetails: UserData) => void
  setAuthToken: (authToken: string) => void
  getUserDetails: () => void
  setGetUserDetails: (value: () => void) => void
  logout: () => void
  setLogout: (value: () => void) => void
  setExpiresIn: (value: number) => void
  authToken?: string
}

export const useUserStore = create<Store>(
  persist(
    set => ({
      userDetails: null,
      getUserDetails: null,
      logout: null,
      expiresIn: null,
      usernameUnmasked: "",
      setUsernameUnmasked: usernameUnmasked => set({usernameUnmasked}),
      setGetUserDetails: getUserDetails => set({getUserDetails}),
      setLogout: logout => set({logout}),
      setUserDetails: (userDetails: UserData) =>
        set(() => ({
          userDetails,
        })),
      setAuthToken: (authToken?: string) => {
        setCookie("user_session", authToken)

        set(() => ({authToken}))
      },
      setExpiresIn: expiresIn => set({expiresIn}),
    }),
    {
      name: "user_settings",
    },
  ),
)
