import React, {
  FC,
  memo,
  Suspense,
  useEffect,
  useLayoutEffect,
  CSSProperties,
  useContext,
  useRef,
} from "react"
import ReactGA from "react-ga4"
import shallow from "zustand/shallow"
import cx from "classnames"
import format from "date-fns/format"
import {
  HOME,
  LIVE_CASINO,
  SLOTS,
  BET365,
  BTI_SPORTS,
  MINI_GAMES,
  FREE_GAMES,
  PUBLIC_PAGES,
  PUBLIC_PATH_MAPPING,
  PRIVATE_PATH_MAPPING,
  PROMOTIONS,
  MAIN_PAGE_WITH_CODES,
  PUBLIC_PATHS,
} from "screens/NavHome/utils/config"
import s from "screens/NavHome/components/NavBody/navBody.module.scss"
import navStore from "screens/NavHome/store/navStore"
import usePopupBannerV3 from "components/Banner/PopupV3/usePopupBanner"
import usePopupBannerV4 from "components/Banner/PopupV4/usePopupBanner"
import usePopupBannerV2 from "components/Banner/Popupv2Mobile/usePopupBanner"
import usePopupBannerV4Store from "components/Banner/PopupV4/usePopupBannerStore"
import usePopupBannerV3Store from "components/Banner/PopupV3/usePopupBannerStore"
// import usePopupBannerV2Store from "components/Banner/Popupv2Mobile/usePopupBannerStore"
import {changeTimeZone} from "screens/Casino/utils/helper"
import {useSearchStore} from "screens/Slots/store/useSearchStore"
import {useMinigameStore} from "stores/miniGameStore"

import useNewsStore from "screens/News/stores/useNewsStore"
import "@egjs/react-flicking/dist/flicking.css"
import DesktopView from "./DesktopView"
import MobileView from "./MobileView"
import {ThemeContext} from "context/ThemeContext"
import {useWindowSize} from "react-use"
import {useUserStore} from "stores/userStore"
interface iNavBody {
  disabledSwipe?: boolean
}

interface CSSCustomProperties extends CSSProperties {
  "--bodyBackground"?: string
}

const NavBody: FC<iNavBody> = ({disabledSwipe}): JSX.Element => {
  const urlPathDebounce = useRef<NodeJS.Timer>(null)
  const {theme} = useContext(ThemeContext)
  const {width} = useWindowSize()

  const custom_colors: CSSCustomProperties = {
    "--bodyBackground": theme?.colors.bodyBackground,
  }

  usePopupBannerV4()
  usePopupBannerV3()
  // usePopupBannerV2()
  const [selectedTitle] = navStore(store => [store.selectedSlideTitle], shallow)

  const {isSlotSearchActive, searchKey} = useSearchStore(
    store => ({
      isSlotSearchActive: store.isSearchOpen,
      searchKey: store.searchKey,
    }),
    shallow,
  )

  const openedGame = useMinigameStore(store => store.openTab, shallow)

  const isNewsOpen = useNewsStore(store => store.isOpen, shallow)

  useEffect(() => {
    if (urlPathDebounce.current) clearTimeout(urlPathDebounce.current)

    try {
      urlPathDebounce.current = setTimeout(() => {
        ReactGA.event({
          category: "PageView",
          action: "User Navigate to Page",
          label: selectedTitle,
        })
      }, 1500)
    } catch (err) {
      console.error(err)
    }
  }, [selectedTitle])

  useLayoutEffect(() => {
    const path = window.location.pathname
    const authUser = useUserStore.getState().authToken
    const PAGE_PATH_MAPPING = authUser ? PRIVATE_PATH_MAPPING : PUBLIC_PATH_MAPPING
    const currentPath = PAGE_PATH_MAPPING[path]
    const isPublicPages = PUBLIC_PAGES.includes(currentPath)
    if (selectedTitle !== HOME || isPublicPages) return

    const ignoredBannerV3Date = localStorage.getItem("home_banner_v3_ignore")
    const ignoredBannerV4Date = localStorage.getItem("home_banner_v4_ignore")
    const koreanDateNow = changeTimeZone(new Date(), "Asia/Seoul")
    const dateNow = format(koreanDateNow, "yyyy-MM-dd")
    if (dateNow !== ignoredBannerV4Date) {
      const popup = usePopupBannerV4Store.getState()
      setTimeout(() => {
        popup.setIsOpen(true)
      }, 5)
    }

    if (dateNow !== ignoredBannerV3Date && width < 1150) {
      const popup = usePopupBannerV3Store.getState()
      // const popupv2 = usePopupBannerV2Store.getState()
      popup.setIsOpen(true)
      // setTimeout(() => {
      //   popupv2.setIsOpen(true)
      // }, 10)
    }
  }, [selectedTitle])

  useLayoutEffect(() => {
    let path = window.location.pathname
    const paths = path.split("/")
    const isMainPages = MAIN_PAGE_WITH_CODES.includes(paths[1])

    if (path === "/" || !isMainPages || paths[1] === "events") path = "/events"

    const authUser = useUserStore.getState().authToken
    const PAGE_PATH_MAPPING = authUser ? PRIVATE_PATH_MAPPING : PUBLIC_PATH_MAPPING
    const currentPath = PAGE_PATH_MAPPING[path]
    const isPublicPages = PUBLIC_PATHS.includes(paths[1])

    if (currentPath !== PROMOTIONS || isPublicPages || paths[1] === "mypage") return

    const ignoredBannerV3Date = localStorage.getItem("home_banner_v3_ignore")
    const ignoredBannerV4Date = localStorage.getItem("home_banner_v4_ignore")
    const koreanDateNow = changeTimeZone(new Date(), "Asia/Seoul")
    const dateNow = format(koreanDateNow, "yyyy-MM-dd")
    if (dateNow !== ignoredBannerV4Date) {
      const popup = usePopupBannerV4Store.getState()
      setTimeout(() => {
        popup.setIsOpen(true)
      }, 5)
    }

    if (dateNow !== ignoredBannerV3Date && width < 1150) {
      const popup = usePopupBannerV3Store.getState()
      // const popupv2 = usePopupBannerV2Store.getState()
      popup.setIsOpen(true)
      // setTimeout(() => {
      //   popupv2.setIsOpen(true)
      // }, 10)
    }
  }, [])

  if (isNewsOpen) return <></>

  return (
    <Suspense fallback={<></>}>
      <div
        className={cx(s.bodyBg, {
          [s.slots]: selectedTitle === SLOTS,
          [s.casino]: selectedTitle === LIVE_CASINO,
          [s.slotSearch]: isSlotSearchActive,
          [s.slotSearchResults]: searchKey?.length > 0,
          [s.eos]: openedGame === 1,
          [s.sports]: selectedTitle === BTI_SPORTS,
          [s.bet365]: selectedTitle === BET365,
          [s.home]: selectedTitle === HOME,
          [s.miniGames]: selectedTitle === MINI_GAMES,
          [s.freeGames]: selectedTitle === FREE_GAMES,
        })}
        style={custom_colors}
      />
      {disabledSwipe ? <DesktopView /> : <MobileView />}
    </Suspense>
  )
}

export default memo(NavBody)
