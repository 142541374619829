import React, {CSSProperties, FC, useContext} from "react"
import cx from "classnames"
import shallow from "zustand/shallow"
import navStore from "screens/NavHome/store/navStore"
import {useUserStore} from "stores/userStore"
import NavItem from "screens/NavHome/components/NavItem"

import s from "screens/NavHome/components/NavList/navList.module.scss"
import {
  PROMOTIONS,
  FREE_GAMES,
  MINI_GAMES_V2,
  FOOTER_PAGES_PATH,
} from "screens/NavHome/utils/config"
import {ThemeContext} from "context/ThemeContext"

interface iNavList {
  className?: string
  items: string[]
  handleClick: (label: string) => void
}
interface CSSCustomProperties extends CSSProperties {
  "--buttonActiveTextColor"?: string
  "--buttonActivebottomLine"?: string
  "--headerHoverColor"?: string
  "--headerActiveColor"?: string
  "--background"?: string
}

const NavList: FC<iNavList> = ({
  className,
  items,
  handleClick,
}): JSX.Element => {
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = {
    "--buttonActiveTextColor": theme?.colors.buttonActiveTextColor,
    "--buttonActivebottomLine": theme?.colors.buttonActivebottomLine,
    "--headerHoverColor": theme?.colors.headerHoverColor,
    "--headerActiveColor": theme?.colors.headerActiveColor,
    "--background": theme?.colors.background,
  }

  const authToken = useUserStore(x => x.authToken, shallow)
  const {selectedSlideTitle, urlPath} = navStore(
    store => ({
      selectedSlideTitle: store.selectedSlideTitle,
      urlPath: store.urlPath
    }),
    shallow,
  )

  const isFooter = FOOTER_PAGES_PATH.includes(urlPath)

  return (
    <div
      className={cx(s.container, className, {[s.hasAuth]: authToken})}
      style={custom_colors}
    >
      {items &&
        items.map(row => (
          <NavItem
            className={cx(s.navItem, {
              [s.active]: selectedSlideTitle === row && !isFooter,
            })}
            key={row}
            handleClick={handleClick}
            label={row}
            isNew={PROMOTIONS === row || MINI_GAMES_V2 === row}
            isFree={FREE_GAMES === row}
          />
        ))}
    </div>
  )
}

export default NavList
