import React, {FC, memo, useEffect} from "react"
import {useQuery} from "react-query"
import {useParams, useHistory} from "react-router-dom"
import punycode from "punycode"
import {
  LIVE_CASINO,
  BTI_SPORTS,
  PRIVATE_PATH_MAPPING,
  PUBLIC_PATH_MAPPING,
  HOME,
  SLOTS,
  MINI_GAMES_V2,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES
} from "screens/NavHome/utils/config"
import useNavHomeController from "screens/NavHome/hooks/useNavHomeController"
import servicePlayer from "services/Player"
import s from "screens/NavHome/navHome.module.scss"
import NavBody from "./components/NavBody"
import useBtiStore from "stores/useBtiStore"
import {useUserStore} from "stores/userStore"
import navStore from "screens/NavHome/store/navStore"

export interface iNavHome {
  isDrawerOpen: boolean
  resizeHome: () => void
  resizeContent: any
}

const NavHome: FC = (): JSX.Element => {
  const {id} = useParams() as {id: string}
  const history = useHistory()
  const {
    isLarge,
    setSelectedTitle,
    setSelectedIndex,
    setSliderIndex,
    handleClick,
  } = useNavHomeController()

  useQuery(
    ["PLAYER_REFERRAL_CODE"],
    async () => {
      const host = window.location.host
      const domain = host.split(".")
      localStorage.setItem("REFERRAL_CODE", "ezbet")
      const request = {
        distributor_name: punycode.toUnicode(domain[0]),
      }

      const resp = await servicePlayer.getReferralCode(request)
      return resp?.data
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const authUser = useUserStore.getState().authToken
        const PAGE_PATH_MAPPING = authUser ? PRIVATE_PATH_MAPPING : PUBLIC_PATH_MAPPING
        const isUrlPath = Object.keys(PAGE_PATH_MAPPING).includes(`/${id}`)

        if(isUrlPath) return

        const host = window.location.host
        const domain = host.split(".")
        const hasSubDomain = domain.length > 2
        const isSecure = domain[0].includes("secure")
        const isCasino = id === "casino" || id === LIVE_CASINO
        const code =
          hasSubDomain && !isSecure ? data?.code : !isCasino && id ? id : data?.code
        localStorage.setItem("REFERRAL_CODE", code || "ezbet")
        const nav = navStore.getState()
        code && nav.setReferralCode(code)
        const pathName = history.location.pathname
          
        if (pathName !== "/" && !authUser) {
          const nav = navStore.getState()
          nav.validateUrlPath()
        }
      },
      onError(data) {
        const pathName = history.location.pathname
        if (pathName !== "/") {
          // window.history.replaceState(null, "", "/events")
          // const nav = navStore.getState()
          // nav.setUrlPath("/events")
        }
      },
    },
  )

  const navToPath = (path: string) => {
    const bti = useBtiStore.getState()
    const nav = navStore.getState()

    switch(path) {
      case "betting-rules":
        bti.setIsBettingRules(true)
        setSelectedIndex(1)
        setSliderIndex(1)
        setSelectedTitle(BTI_SPORTS)
        handleClick(BTI_SPORTS)
        nav.setUrlPath("/betting-rules")
        break;
      case "casino":
      case "livecasino":
      case "live-casino":
        handleClick(LIVE_CASINO)
        break;
      case "home":
        handleClick(HOME)
        break;
      case "sports":
        handleClick(BTI_SPORTS)
        break;
      case "slots":
        handleClick(SLOTS)
        break;
      case "gameshows":
        handleClick(MINI_GAMES_V2)
        break;
      case "skypark":
        handleClick(SKY_PARK)
        break;
      case "events":
        handleClick(PROMOTIONS)
        break;
      case "freegame":
        handleClick(FREE_GAMES)
        break;
      default:
        break;
    }
  }

  const pathWithCode = (path: string, code?: string) => {
    const nav = navStore.getState()
    const referral = code ?? "ezbet"
    localStorage.setItem("REFERRAL_CODE", referral)
    nav.setReferralCode(code)

    navToPath(path)
  }

  useEffect(() => {
    const path = id?.toLocaleLowerCase()
    const urlPath = history.location.pathname
    const paths = urlPath?.split("/")
    const hasCode = paths?.length > 2

    if(hasCode) {
      pathWithCode(paths[1], paths[2])
    } else {
      navToPath(path)
    }
  }, [id])


  return <NavBody disabledSwipe={isLarge} />
}

export default memo(NavHome)
