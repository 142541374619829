import {useCallback, useEffect} from "react"
import navStore from "screens/NavHome/store/navStore"
import {PUBLIC_PATH_MAPPING, FREE_GAMES, PRIVATE_PATH_MAPPING} from "screens/NavHome/utils/config"
import {useFreeGameStore} from "stores/freeGameStore"
import {useUserStore} from "stores/userStore"
import shallow from "zustand/shallow"
import isEmpty from "lodash/isEmpty"

const useFreeGameNavBarProvider = () => {
    const authToken = useUserStore(store => store.authToken, shallow)
  const [selectedSlideTitle] = navStore(state => [state.selectedSlideTitle], shallow)
  
  const {code, games } = useFreeGameStore(
    store => ({
      code: store.selected,
      games: store.gamesArrayData,
    }),
    shallow,
  )

  const initSubPage = useCallback((to: string) => {
    const nav = useFreeGameStore.getState()
    const games = nav.gamesArrayData
    const selectedGame = games.find(item => item.code === to);
    if (selectedGame) {
      nav.setSelected(selectedGame?.code)
    }
  }, [])
  
  useEffect(() => {
    const nav = navStore.getState()
    const isFreeGames = nav.selectedSlideTitle === FREE_GAMES

    if (isEmpty(games) || !isFreeGames) return
    
    const urlPath = nav.urlPath
    const PAGE_PATH_MAPPING = authToken ? PRIVATE_PATH_MAPPING : PUBLIC_PATH_MAPPING
    const page = PAGE_PATH_MAPPING[urlPath]
    const items = page?.split("/")
    const hasSubPage = items?.length > 1
    
    if (hasSubPage) {
      initSubPage(items[1])
    }
  }, [games, selectedSlideTitle, authToken])

  useEffect(() => {
    const nav = navStore.getState()
    const isFreeGames = nav.selectedSlideTitle === FREE_GAMES
    if (isEmpty(code)|| !isFreeGames) return

    const key = `${FREE_GAMES}/${code}`
    nav.updateUrlPath(key)
  }, [code, selectedSlideTitle])
}

export default useFreeGameNavBarProvider
